<template>
    <h2 class="text-2xl font-bold text-[#F7287B] p-2">ArchiFEC</h2>
    <div class="w-full h-full p-4 bg-gray-100 flex flex-col items-center">

        <!-- Vérifie si une entreprise est sélectionnée -->
        <div class="w-full h-full" v-if="companySelected != ''">

            <!-- Sélection de l'exercice -->
            <div class="mb-4 w-full max-w-lg text-[#7209B7]">
                <label for="exercise-select" class="block font-medium mb-2">Sélectionnez un exercice</label>
                <select v-model="selectedExercise" id="exercise-select"
                    class="w-full border border-gray-300 rounded-md p-2 bg-white shadow-sm focus:ring focus:ring-[#7209B7] focus:outline-none transition duration-150 ease-in-out">
                    <option value="" disabled selected>Sélectionnez un exercice...</option>
                    <option v-for="exercise in exercises" :key="exercise.id" :value="exercise">
                        {{ exercise.name }}
                    </option>
                </select>
            </div>

            <!-- Choix du type de FEC avec boutons radio -->
            <div class="flex justify-around mb-6">
                <label class="inline-flex items-center cursor-pointer">
                    <input type="radio" class="form-radio h-5 w-5 text-[#7209B7]" value="achats" v-model="fecType" />
                    <span class="ml-2 text-lg text-[#7209B7] font-semibold">FEC Achats</span>
                </label>
                <label class="inline-flex items-center cursor-pointer">
                    <input type="radio" class="form-radio h-5 w-5 text-[#7209B7]" value="complet" v-model="fecType" />
                    <span class="ml-2 text-lg text-[#7209B7] font-semibold">FEC Complet</span>
                </label>
            </div>

            <!-- Bouton pour générer le FEC -->
            <div class="flex justify-center mb-6">
                <button @click="generateFEC" :disabled="!selectedExercise || !fecType"
                    class="px-6 py-3 bg-[#7209B7] text-white font-semibold rounded-lg shadow hover:bg-[#4CC9F0] transition-colors disabled:opacity-50">
                    Générer le FEC
                </button>
            </div>

            <!-- Affichage du tableau FEC Achats -->
            <div v-if="fecType === 'achats' && fecGenerated" class="w-full font-sans">
                <h3 class="text-lg font-semibold text-[#7209B7] mb-4 text-center">FEC Achats Généré</h3>
                <div class="overflow-x-auto max-h-96 overflow-y-auto shadow-lg rounded-lg border border-gray-300">
                    <table class="min-w-full table-auto bg-white">
                        <thead class="bg-gray-100 sticky top-0">
                            <tr>
                                <th v-for="header in tableHeaders" :key="header"
                                    class="px-4 py-2 border text-left text-sm font-semibold text-[#7209B7] bg-gray-200 sticky top-0">
                                    {{ header }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="entry in fecData" :key="entry.EcritureNum" class="hover:bg-gray-100">
                                <td class="px-4 py-2 border text-sm text-gray-700">{{ entry.JournalCode }}</td>
                                <td class="px-4 py-2 border text-sm text-gray-700">{{ entry.JournalLib }}</td>
                                <td class="px-4 py-2 border text-sm text-gray-700">{{ entry.EcritureNum }}</td>
                                <td class="px-4 py-2 border text-sm text-gray-700">{{ entry.EcritureDate }}</td>
                                <td class="px-4 py-2 border text-sm text-gray-700">{{ entry.CompteNum }}</td>
                                <td class="px-4 py-2 border text-sm text-gray-700">{{ entry.CompteLib }}</td>
                                <td class="px-4 py-2 border text-sm text-gray-700">{{ entry.CompteAuxNum }}</td>
                                <td class="px-4 py-2 border text-sm text-gray-700">{{ entry.CompteAuxLib }}</td>
                                <td class="px-4 py-2 border text-sm text-gray-700">{{ entry.PieceRef }}</td>
                                <td class="px-4 py-2 border text-sm text-gray-700">{{ entry.PieceDate }}</td>
                                <td class="px-4 py-2 border text-sm text-gray-700">{{ entry.EcritureLib }}</td>
                                <td class="px-4 py-2 border text-sm text-gray-700">{{ entry.Debit }}</td>
                                <td class="px-4 py-2 border text-sm text-gray-700">{{ entry.Credit }}</td>
                                <td class="px-4 py-2 border text-sm text-gray-700">{{ entry.EcritureLet }}</td>
                                <td class="px-4 py-2 border text-sm text-gray-700">{{ entry.DateLet }}</td>
                                <td class="px-4 py-2 border text-sm text-gray-700">{{ entry.ValidDate }}</td>
                                <td class="px-4 py-2 border text-sm text-gray-700">{{ entry.MontantDevise }}</td>
                                <td class="px-4 py-2 border text-sm text-gray-700">{{ entry.Idevise }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <!-- Bouton pour télécharger le fichier FEC Achats -->
                <div class="mt-4">
                    <button @click="downloadFEC"
                        class="px-6 py-3 bg-[#7209B7] text-white font-semibold rounded-lg shadow hover:bg-[#4CC9F0] transition-colors">
                        Télécharger le FEC Achats
                    </button>
                </div>
            </div>

            <!-- Affichage des résultats ou messages -->
            <div v-if="resultMessage" class="mt-4 p-4 bg-blue-100 text-blue-700 rounded-lg">
                {{ resultMessage }}
            </div>
        </div>

        <!-- Message si aucune entreprise n'est sélectionnée -->
        <div v-else>
            <p class="text-gray-500">Aucune entreprise sélectionnée.</p>
        </div>
    </div>
</template>

<script>
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import axios from 'axios';

export default {
    setup() {
        const store = useStore();
        const selectedExercise = ref(null);
        const exercises = ref([]);
        const fecType = ref('achats'); // Défaut sur Achats
        const fecGenerated = ref(false);  // Indique si le FEC est généré
        const fecData = ref([]);  // Données du FEC généré
        const resultMessage = ref('');

        // En-têtes du tableau
        const tableHeaders = ref([
            'JournalCode', 'JournalLib', 'EcritureNum', 'EcritureDate', 'CompteNum', 'CompteLib',
            'CompteAuxNum', 'CompteAuxLib', 'PieceRef', 'PieceDate', 'EcritureLib', 'Debit',
            'Credit', 'EcritureLet', 'DateLet', 'ValidDate', 'MontantDevise', 'Idevise'
        ]);

        // Récupère l'entreprise sélectionnée depuis le store
        const companySelected = computed(() => store.state.companySelected);

        // Fetch les exercices comptables de l'entreprise
        const fetchExercises = async () => {
            if (companySelected.value) {
                try {
                    const response = await axios.get(`${process.env.VUE_APP_BASE_URL}/accounting_exercice/${companySelected.value.id}`);
                    exercises.value = response.data.map(exercise => {
                        const startDate = new Date(exercise.exercice_start);
                        const endDate = new Date(exercise.exercice_end);
                        const formatDate = (date) => `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
                        exercise.name = `${formatDate(startDate)} - ${formatDate(endDate)}`;
                        return exercise;
                    });
                } catch (error) {
                    console.error('Erreur lors de la récupération des exercices:', error);
                }
            } else {
                exercises.value = [];
            }
        };

        // Logique pour générer le FEC
        const generateFEC = async () => {
            try {
                if (fecType.value === 'complet') {
                    // Pour le FEC Complet, l'API renvoie directement le fichier à télécharger
                    const url = `${process.env.VUE_APP_BASE_URL}/fecExport/${companySelected.value.id}`;
                    const body = {
                        accounting_year: {
                            exercice_start: selectedExercise.value.exercice_start,
                            exercice_end: selectedExercise.value.exercice_end
                        }
                    };
                    const response = await axios.post(url, body, { responseType: 'blob' });

                    // Télécharger le fichier
                    const blob = new Blob([response.data], { type: 'text/plain' });
                    const downloadLink = document.createElement('a');
                    downloadLink.href = URL.createObjectURL(blob);
                    downloadLink.download = `export_FEC_complet.txt`;
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    document.body.removeChild(downloadLink);

                    resultMessage.value = "Le FEC Complet a été téléchargé.";
                } else {
                    // Pour le FEC Achats, affichage des données dans le tableau
                    const url = `${process.env.VUE_APP_BASE_URL}/fecNotDownloaded/${companySelected.value.id}`;
                    const response = await axios.get(url);
                    //Réponse si le Fec est vide
                    if (response.data.length === 0) {
                        resultMessage.value = "Aucune nouvelle facture à télécharger.";
                        return;
                    }
                    // Traitement des données FEC
                    fecData.value = response.data.flatMap(item => {
                        return item.fec.split('\n').map(line => {
                            const [
                                JournalCode, JournalLib, EcritureNum, EcritureDate, CompteNum, CompteLib,
                                CompteAuxNum, CompteAuxLib, PieceRef, PieceDate, EcritureLib, Debit,
                                Credit, EcritureLet, DateLet, ValidDate, MontantDevise, Idevise
                            ] = line.split(';');

                            return {
                                JournalCode, JournalLib, EcritureNum, EcritureDate, CompteNum, CompteLib,
                                CompteAuxNum, CompteAuxLib, PieceRef, PieceDate, EcritureLib, Debit,
                                Credit, EcritureLet, DateLet, ValidDate, MontantDevise, Idevise
                            };
                        });
                    });
                    fecGenerated.value = true;
                    resultMessage.value = "Le FEC Achats a été généré avec succès.";
                }
            } catch (error) {
                console.error("Erreur lors de la génération du FEC:", error);
                resultMessage.value = "Une erreur est survenue lors de la génération du FEC.";
            }
        };

        // Fonction pour télécharger le FEC Achats
        const downloadFEC = () => {
            const fecText = fecData.value.map(entry => Object.values(entry).join(';')).join('\n');
            const blob = new Blob([fecText], { type: 'text/plain' });
            const downloadLink = document.createElement('a');
            downloadLink.href = URL.createObjectURL(blob);
            downloadLink.download = `export_FEC_achats.txt`;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        };

        // Surveiller les changements d'entreprise sélectionnée
        watch(companySelected, () => {
            fetchExercises();
        }, { immediate: true });

        return {
            companySelected,
            selectedExercise,
            exercises,
            fecType,
            fecGenerated,
            fecData,
            resultMessage,
            generateFEC,
            downloadFEC,
            tableHeaders
        };
    },
};
</script>
