<template lang="">
    <div class="h-full w-[15%] bg-[#F72585] text-white">
        <ul>
            <li class="h-4">
            </li>
            <li>
                <h2 class="text-2xl font-bold p-1">ArchiPilot</h2>
            </li>
            <br>
            <li class="hover:bg-[#4CC9F0]" @click="navigateToEvoliz">
                <span class="p-2">Gestion Commerciale </span>
            </li>
            <li class="mt-4 hover:bg-[#4CC9F0]">
                <router-link to="/archiPilot/ArchiPilotClient" class="p-2">Création Compte Client </router-link>
            </li>
            <li class="mt-4 hover:bg-[#4CC9F0]">
                <span class="p-2">Résultat de l'Exercice </span>
            </li>
            <li class="mt-4 hover:bg-[#4CC9F0]">
                <span class="p-2">Gestion des Immobilisations </span>
            </li>
            <li class="mt-4 hover:bg-[#4CC9F0]">
                <span class="p-2">Gestion des Subventions </span>
            </li>
            <li v-if="user.role == 'super_admin'" class="mt-4 hover:bg-[#4CC9F0]">
                <router-link to="/archiPilot/addCompany" class="p-2">Création Nouvelle Entreprise </router-link>
            </li>
            <li v-if="user.role == 'super_admin'" class="mt-4 hover:bg-[#4CC9F0]">
                <router-link to="/archiPilot/param" class="p-2">Parametrage </router-link>
            </li>
        </ul>
    </div>
</template>
<script>
import {useStore} from "vuex"
export default {
    
    setup(){
        const store = useStore()
        const user = store.state.user
        const navigateToEvoliz = () => {
            window.open("https://www.evoliz.com/login.php", "_blank")
        }
        return{
            navigateToEvoliz,
            user
        }
    }
}
</script>
<style lang="">
    
</style>