<template lang="">
    <div>
        <div class="absolute inset-0 bg-[#EBF9FC] opacity-80"></div>

        <!-- Main modal -->
        <div tabindex="-1" class="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-screen">
            <div class="relative p-4 w-full max-w-md max-h-full">
            <!-- Modal content -->
                <div class="relative bg-white rounded-lg shadow">
                <!-- Modal header -->
                    <div class="flex items-center justify-between p-4 md:p-2 border-b rounded-t">
                        <h3 class="text-lg font-semibold text-[#F72585]">Créer un nouveau Compte Client</h3>
                        <button type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center" @click="$emit('closeModal')">
                            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                            </svg>
                            <span class="sr-only">Close modal</span>
                        </button>
                    </div>
                <!-- Modal body -->
                    <form class="p-4 md:p-5" @submit.prevent = "createClient">
                        <div>
                            <div>
                                <label for="clientType" class="block mb-2 text-base font-medium text-[#7209B7]">Type de client</label>
                                <select required v-model="clientType" class="bg-[#F0FAFE] border border-[#7209B7] text-[#3A0CA3] text-sm rounded-lg focus:outline-none focus:ring-2 focus:ring-[#7209B7] focus:border-[#4CC9F0] block w-full p-2.5">
                                    <option disabled value="">Selectionnez une catégorie</option>
                                    <option value="Particulier">Particulier</option>
                                    <option value="Professionnel">Professionnel</option>
                                    <option value="Administration publique">Administration publique</option>
                                </select>
                            </div>
                            <br>

                            <div v-if="clientType=='Professionnel' || clientType=='Administration publique'">
                            
                                <div>
                                <label for="siren" class="block mb-2 text-sm font-medium text-[#7209B7]">SIREN</label>
                                <input required v-model="siren" type="text" name="siren" class="bg-[#F0FAFE] border border-[#7209B7] text-[#3A0CA3] text-sm rounded-lg focus:outline-none focus:ring-2 focus:ring-[#7209B7] focus:border-[#4CC9F0] block w-full p-2.5" placeholder="SIREN">
                                </div>
                                <br>

                                <div>
                                <label for="delivery" class="block mb-2 text-base font-medium text-[#7209B7]">Adresse de livraison différente ?</label>
                                <select required v-model="delivery" class="bg-[#F0FAFE] border border-[#7209B7] text-[#3A0CA3] text-sm rounded-lg focus:outline-none focus:ring-2 focus:ring-[#7209B7] focus:border-[#4CC9F0] block w-full p-2.5">
                                    <option disabled value="">Selectionnez une option</option>
                                    <option value="Oui">Oui</option>
                                    <option value="Non">Non</option>
                                </select>
                                </div>
                                <br>

                                <div v-if="delivery=='Oui'">
                                    <div>
                                    <label for="siret" class="block mb-2 text-sm font-medium text-[#7209B7]">SIRET de l'établissement de livraison</label>
                                    <input required v-model="siret" type="text" name="siret" class="bg-[#F0FAFE] border border-[#7209B7] text-[#3A0CA3] text-sm rounded-lg focus:outline-none focus:ring-2 focus:ring-[#7209B7] focus:border-[#4CC9F0] block w-full p-2.5" placeholder="SIRET de l'établissement de livraison">
                                    </div>
                                </div>
                                <br>

                            </div>

                            <div v-if="clientType=='Particulier'">
                                <div>
                                <label for="civility" class="block mb-2 text-base font-medium text-[#7209B7]">Civilité</label>
                                <select required v-model="civility" class="bg-[#F0FAFE] border border-[#7209B7] text-[#3A0CA3] text-sm rounded-lg focus:outline-none focus:ring-2 focus:ring-[#7209B7] focus:border-[#4CC9F0] block w-full p-2.5">
                                    <option disabled value="">Selectionnez une civilité</option>
                                    <option value="MME">MME</option>
                                    <option value="MR">MR</option>
                                    <option value="Autre">Autre</option>
                                </select>
                                </div>
                                <br>

                                <div>
                                    <label for="name" class="block mb-2 text-sm font-medium text-[#7209B7]">NOM Prénom</label>
                                    <input required v-model="name" type="text" name="name" class="bg-[#F0FAFE] border border-[#7209B7] text-[#3A0CA3] text-sm rounded-lg focus:outline-none focus:ring-2 focus:ring-[#7209B7] focus:border-[#4CC9F0] block w-full p-2.5" placeholder="NOM Prénom">
                                </div>
                                <br>

                                <div>
                                    <label for="postCode" class="block mb-2 text-sm font-medium text-[#7209B7]">Code postal</label>
                                    <input required v-model="postCode" type="text" name="postCode" class="bg-[#F0FAFE] border border-[#7209B7] text-[#3A0CA3] text-sm rounded-lg focus:outline-none focus:ring-2 focus:ring-[#7209B7] focus:border-[#4CC9F0] block w-full p-2.5" placeholder="Code postal">
                                </div>
                                <br>

                                <div>
                                    <label for="town" class="block mb-2 text-sm font-medium text-[#7209B7]">VILLE</label>
                                    <input required v-model="town" type="text" name="town" class="bg-[#F0FAFE] border border-[#7209B7] text-[#3A0CA3] text-sm rounded-lg focus:outline-none focus:ring-2 focus:ring-[#7209B7] focus:border-[#4CC9F0] block w-full p-2.5" placeholder="VILLE">
                                </div>
                                <br>

                                <div>
                                    <label for="address" class="block mb-2 text-sm font-medium text-[#7209B7]">Adresse</label>
                                    <input required v-model="address" type="text" name="address" class="bg-[#F0FAFE] border border-[#7209B7] text-[#3A0CA3] text-sm rounded-lg focus:outline-none focus:ring-2 focus:ring-[#7209B7] focus:border-[#4CC9F0] block w-full p-2.5" placeholder="Adresse">
                                </div>
                                <br>

                                <div>
                                    <label for="address2" class="block mb-2 text-sm font-medium text-[#7209B7]">Complément d'adresse</label>
                                    <input v-model="address2" type="text" name="address2" class="bg-[#F0FAFE] border border-[#7209B7] text-[#3A0CA3] text-sm rounded-lg focus:outline-none focus:ring-2 focus:ring-[#7209B7] focus:border-[#4CC9F0] block w-full p-2.5" placeholder="Complément d'adresse">
                                </div>
                                <br>
                                <div>
                                <label for="clientCountry" class="block mb-2 text-base font-medium text-[#7209B7]">Pays</label>
                                <select required v-model="clientCountry" class="bg-[#F0FAFE] border border-[#7209B7] text-[#3A0CA3] text-sm rounded-lg focus:outline-none focus:ring-2 focus:ring-[#7209B7] focus:border-[#4CC9F0] block w-full p-2.5">
                                    <option disabled value="">Selectionnez un pays</option>
                                    <option v-for="(countryName, countryCode) in countries" :key="countryCode" :value="countryCode">
                                        {{ countryName }}
                                        </option>
                                    </select>
                                    </div>
                                    <br>

                                <div>
                                <label for="delivery" class="block mb-2 text-base font-medium text-[#7209B7]">Adresse de livraison différente ?</label>
                                <select required v-model="delivery" class="bg-[#F0FAFE] border border-[#7209B7] text-[#3A0CA3] text-sm rounded-lg focus:outline-none focus:ring-2 focus:ring-[#7209B7] focus:border-[#4CC9F0] block w-full p-2.5">
                                    <option disabled value="">Selectionnez une option</option>
                                    <option value="Oui">Oui</option>
                                    <option value="Non">Non</option>
                                </select>
                                </div>
                                <br>

                                <div v-if="delivery=='Oui'">
                                    <div>
                                    <label for="deliveryPostCode" class="block mb-2 text-sm font-medium text-[#7209B7]">Adresse de livraison - Code postal</label>
                                    <input required v-model="deliveryPostCode" type="text" name="deliveryPostCode" class="bg-[#F0FAFE] border border-[#7209B7] text-[#3A0CA3] text-sm rounded-lg focus:outline-none focus:ring-2 focus:ring-[#7209B7] focus:border-[#4CC9F0] block w-full p-2.5" placeholder="Adresse de livraison - Code postal">
                                    </div>
                                    <br>

                                    <div>
                                    <label for="deliveryTown" class="block mb-2 text-sm font-medium text-[#7209B7]">Adresse de livraison - VILLE</label>
                                    <input required v-model="deliveryTown" type="text" name="deliveryTown" class="bg-[#F0FAFE] border border-[#7209B7] text-[#3A0CA3] text-sm rounded-lg focus:outline-none focus:ring-2 focus:ring-[#7209B7] focus:border-[#4CC9F0] block w-full p-2.5" placeholder="Adresse de livraison - VILLE">
                                    </div>
                                    <br>

                                    <div>
                                    <label for="deliveryAddress" class="block mb-2 text-sm font-medium text-[#7209B7]">Adresse de livraison - Adresse</label>
                                    <input required v-model="deliveryAddress" type="text" name="deliveryAddress" class="bg-[#F0FAFE] border border-[#7209B7] text-[#3A0CA3] text-sm rounded-lg focus:outline-none focus:ring-2 focus:ring-[#7209B7] focus:border-[#4CC9F0] block w-full p-2.5" placeholder="Adresse de livraison - Adresse">
                                    </div>
                                    <br>

                                    <div>
                                    <label for="deliveryAddress2" class="block mb-2 text-sm font-medium text-[#7209B7]">Adresse de livraison - Complément d'adresse</label>
                                    <input v-model="deliveryAddress2" type="text" name="deliveryAddress2" class="bg-[#F0FAFE] border border-[#7209B7] text-[#3A0CA3] text-sm rounded-lg focus:outline-none focus:ring-2 focus:ring-[#7209B7] focus:border-[#4CC9F0] block w-full p-2.5" placeholder="Adresse de livraison - Complément d'adresse">
                                    </div>
                                    <br>
                                    <div>
                                <label for="deliveryCountry" class="block mb-2 text-base font-medium text-[#7209B7]">Pays</label>
                                <select required v-model="deliveryCountry" class="bg-[#F0FAFE] border border-[#7209B7] text-[#3A0CA3] text-sm rounded-lg focus:outline-none focus:ring-2 focus:ring-[#7209B7] focus:border-[#4CC9F0] block w-full p-2.5">
                                    <option disabled value="">Selectionnez un pays</option>
                                    <option v-for="(countryName, countryCode) in countries" :key="countryCode" :value="countryCode">
                                        {{ countryName }}
                                        </option>
                                    </select>
                                    </div>
                                    <br>

                                </div>
                                <br>
                                <br>


                            </div>
                            <div v-if="clientType!=''">
                                <div class="flex items-center justify-between p-4 md:p-2 border-b rounded-t">
                                <h4 class="text-base font-semibold text-[#F72585]">Informations de Contact</h4>
                                <br>
                                </div>

                                <div>
                                <label for="contactCivility" class="block mb-2 text-sm font-medium text-[#7209B7]">Civilité du contact</label>
                                <select required v-model="contactCivility" class="bg-[#F0FAFE] border border-[#7209B7] text-[#3A0CA3] text-sm rounded-lg focus:outline-none focus:ring-2 focus:ring-[#7209B7] focus:border-[#4CC9F0] block w-full p-2.5">
                                    <option disabled value="">Selectionnez une civilité</option>
                                    <option value="MME">MME</option>
                                    <option value="MR">MR</option>
                                    <option value="Autre">Autre</option>
                                </select>
                                </div>
                                <br>

                                <div>
                                    <label for="firstName" class="block mb-2 text-sm font-medium text-[#7209B7]">Prénom du contact</label>
                                    <input required v-model="firstName" type="text" name="FirstName" class="bg-[#F0FAFE] border border-[#7209B7] text-[#3A0CA3] text-sm rounded-lg focus:outline-none focus:ring-2 focus:ring-[#7209B7] focus:border-[#4CC9F0] block w-full p-2.5" placeholder="Prénom du contact">
                                </div>
                                <br>

                                <div>
                                    <label for="lastName" class="block mb-2 text-sm font-medium text-[#7209B7]">NOM du contact</label>
                                    <input required v-model="lastName" type="text" name="lastName" class="bg-[#F0FAFE] border border-[#7209B7] text-[#3A0CA3] text-sm rounded-lg focus:outline-none focus:ring-2 focus:ring-[#7209B7] focus:border-[#4CC9F0] block w-full p-2.5" placeholder="NOM du contact">
                                </div>
                                <br>

                                <div>
                                    <label for="mail" class="block mb-2 text-sm font-medium text-[#7209B7]">Mail du contact</label>
                                    <input required v-model="mail" type="text" name="mail" class="bg-[#F0FAFE] border border-[#7209B7] text-[#3A0CA3] text-sm rounded-lg focus:outline-none focus:ring-2 focus:ring-[#7209B7] focus:border-[#4CC9F0] block w-full p-2.5" placeholder="contact@exemple.fr">
                                </div>
                                <br>

                                <div>
                                    <label for="role" class="block mb-2 text-sm font-medium text-[#7209B7]">Fonction / Rôle du contact</label>
                                    <input required v-model="role" type="text" name="role" class="bg-[#F0FAFE] border border-[#7209B7] text-[#3A0CA3] text-sm rounded-lg focus:outline-none focus:ring-2 focus:ring-[#7209B7] focus:border-[#4CC9F0] block w-full p-2.5" placeholder="Fonction / Rôle du contact">
                                </div>
                                <br>

                                <div>
                                    <label for="phone" class="block mb-2 text-sm font-medium text-[#7209B7]">Téléphone du contact</label>
                                    <input required v-model="phone" type="text" name="phone" class="bg-[#F0FAFE] border border-[#7209B7] text-[#3A0CA3] text-sm rounded-lg focus:outline-none focus:ring-2 focus:ring-[#7209B7] focus:border-[#4CC9F0] block w-full p-2.5" placeholder="Téléphone du contact">
                                </div>
                                <br>
                                

                            </div>
                        </div>
                        <button type="submit" class="text-white inline-flex items-center bg-[#7209B7] hover:bg-[#4CC9F0] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                            <svg class="me-1 -ms-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd"></path></svg>Ajouter le Compte Client
                        </button>

                        <div v-if ="isError">
                            <p class="text-red-500 font-bold mt-2 text-base">
                                {{message}}
                            </p>
                        </div>
                        <div v-if ="isSuccess">
                            <p class="text-green-500 font-bold">
                                {{message}}
                            </p>
                        </div>
                    </form>
                </div>
            </div>
        </div>   
    </div>
</template>

<script>
import { ref } from "vue";
import axios from "axios";
import { useStore } from "vuex";
import isoCountries from "@/assets/iso_countries.json"
export default {
    setup() {
        var clientType = ref("")
        var siren = ref("")
        var civility = ref("")
        var name = ref("")
        var delivery = ref("")
        var siret = ref("")
        var postCode = ref("")
        var town = ref("")
        var address = ref("")
        var address2 = ref("")
        var deliveryPostCode = ref("")
        var deliveryTown = ref("")
        var deliveryAddress = ref("")
        var deliveryAddress2 = ref("")
        var contactCivility = ref("")
        var firstName = ref("")
        var lastName = ref("")
        var mail = ref("")
        var role = ref("")
        var phone = ref("")
        var isError = ref(false)
        var isSuccess = ref(false)
        var message = ref("")
        var store = useStore()
        var countries = ref(isoCountries)
        var deliveryCountry = ref("")
        var clientCountry = ref("")
        async function createClient() {
            try {
                var postObject = {
                    companyId: store.state.companySelected.id,
                    evolizCompanyId: store.state.companySelected.evoliz_id,
                    clientType: clientType.value,
                    siren: siren.value,
                    civility: civility.value,
                    name: name.value,
                    delivery: delivery.value,
                    siret: siret.value,
                    postCode: postCode.value,
                    town: town.value,
                    address: address.value,
                    address2: address2.value,
                    deliveryPostCode: deliveryPostCode.value,
                    deliveryTown: deliveryTown.value,
                    deliveryAddress: deliveryAddress.value,
                    deliveryAddress2: deliveryAddress2.value,
                    deliveryCountryIso2: deliveryCountry.value,
                    contactCivility: contactCivility.value,
                    firstName: firstName.value,
                    lastName: lastName.value,
                    mail: mail.value,
                    role: role.value,
                    phone: phone.value,
                    clientCountryIso2: clientCountry.value,
                }
                let url = `${process.env.VUE_APP_BASE_URL}/client/`;
                var response = await axios.post(url, postObject)

                isSuccess.value = true
                message.value = response.data.message

                clientType.value = ""
                siren.value = ""
                civility.value = ""
                name.value = ""
                delivery.value = ""
                siret.value = ""
                postCode.value = ""
                town.value = ""
                address.value = ""
                address2.value = ""
                deliveryPostCode.value = ""
                deliveryTown.value = ""
                deliveryAddress.value = ""
                deliveryAddress2.value = ""
                contactCivility.value = ""
                firstName.value = ""
                lastName.value = ""
                mail.value = ""
                role.value = ""
                phone.value = ""
                deliveryCountry.value = ""
                clientCountry.value = ""
                setTimeout(() => {
                    isSuccess.value = false
                    message.value = ""
                }, 8000);


            } catch (error) {
                console.log(error)
                console.log("erreur", error.response)
                if (error.response.status == 404) {
                    isError.value = true
                    message.value = error.response.data.errorMessage
                    setTimeout(() => {
                        isError.value = false
                        message.value = ""
                    }, 4000);
                }
                if (error.response.status == 500) {
                    console.log("erreur 500")
                    isError.value = true
                    message.value = error.response.data.message
                    setTimeout(() => {
                        isError.value = false
                        message.value = ""
                    }, 4000);
                }
            }
        }

        return {
            clientType,
            siren,
            delivery,
            siret,
            civility,
            name,
            postCode,
            town,
            address,
            address2,
            deliveryPostCode,
            deliveryTown,
            deliveryAddress,
            deliveryAddress2,
            contactCivility,
            firstName,
            lastName,
            mail,
            role,
            phone,
            createClient,
            isError,
            isSuccess,
            message,
            countries,
            deliveryCountry,
            clientCountry
        }
    }
}
</script>
<style scoped lang="">

</style>