<template>
    <div class="min-h-screen bg-[#7209B7] text-center flex flex-col items-center justify-start">
      <!-- Header avec le logo et le texte -->
      <div class="items-center mb-5">
        <h2 class="text-2xl text-white">{{ clientName }}, Bienvenue dans votre Espace Client</h2>
      </div>
  
      <!-- Section Vos Applications -->
      <div class="mb-8">
        <h3 class="text-4xl text-[#F7287B] font-bold">Vos Applications</h3>
      </div>
  
      <!-- Grid des applications -->
      <div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-6 max-w-6xl">
        <AppCard
          v-for="(app, index) in apps"
          :key="index"
          :name="app.name"
          :description="app.description"
          :color="app.color"
          :link="app.link"
        />
      </div>
    </div>
  </template>
  
  <script setup>
  import AppCard from '@/components/AppCard.vue';
  import { useStore } from 'vuex';
  import { computed } from 'vue';
  const store = useStore();
  const clientName = computed(() => { if (store.state.user) { return store.state.user.name } else return "" });
  const apps = [
    {
      name: 'ArchiPilot',
      description: 'Pilotage complet de votre entreprise : Facturation Électronique, Rapprochement Bancaire, Gestion du Conteneur Client, ...',
      color: '#3A0CA3',
      link: '/archipilot'
    },
    {
      name: 'FacturEase',
      description: "Pré-saisie des documents d'achats : Collecte Automatique, Traitement par OCR, ...",
      color: '#4361EE',
      link: '/iaPreview2'
    },
    {
      name: 'ArchiGED',
      description: "Gestion électronique de vos fichiers afin de satisfaire à vos obligations de conservation des documents comptables.",
      color: '#4CC9F0',
      link: '/archiged'
    },
    {
      name: 'AlpaChat',
      description: "ChatBot automatisé : Réponse aux appels manqués, Prise de Rendez-Vous, Suivi des Conversations, ...",
      color: '#7209B7',
      link: '#'
    },
    {
      name: 'ArchiStock',
      description: "Gestion des stocks d'approvisionnement : Coût Unitaire Moyen Pondéré, Valorisation des stocks finis, ...",
      color: '#F72585',
      link: '#'
    },
    {
      name: 'ArchiFEC',
      description: "Génération du Fichier des Écritures Comptables : Compatibilité avec les Logiciels de Comptabilité, Normes Françaises, ...",
      color: '#B5179E',
      link: '/archifec'
    },
    {
      name: 'LegiFlex',
      description: "Analyse comparative des structures juridiques : Recherche de la Structure Optimale, Maximisation de la Rémunération Nette, ...",
      color: '#480CA8',
      link: '#'
    },
    {
      name: 'At-Expo',
      description: "Génération d'expositions virtuelles : Génération Automatique de Site Web, Templates Variés, Personnalisation, ...",
      color: '#3A0CA3',
      link: '#'
    }
  ];
  </script>
  
  <style scoped>
  /* Styles généraux */
  </style>
  