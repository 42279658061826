<template>
    <div>
        <div>
            <h3 style="text-align:center; font-size:25px"><b>EVOLIZ</b></h3>
        </div>
        <div class="evoliz-container">
            <span v-if="companySelected">Evoliz ID: {{ evolizId }}</span>
        </div>
        <div class="evoliz-parametrage">
            <h4>Paramétrage Evoliz</h4>
            <div class="evoliz-parametrage-buttons">
                <q-btn @click="copyMailTemplates" color="primary">Copier les mails</q-btn>
                <CreateRecoveryTemplates />
                <UpdateTemplates />
            </div>
            
        </div>
        <div class="evoliz-requests">
            <div class="evoliz-parameters">
                <h4>Requêtes Evoliz</h4>

                <div style="margin-left: 2Opx; width: 300px;">
                    <q-select dense filled v-model="requestSelected" :options="requests" label="Sélectionner la requête"
                        option-label="type_label" emit-value map-options @update:model-value="requestHandleSelect" />
                </div>
                <div>
                    <div v-for="(param, index) in params" :key="param.id">
                        <q-input :label="param" v-model="paramsValues[index].value" />
                    </div>
                    <div v-for="(queryParam, index) in queryParameters" :key="queryParam.id">
                        <q-input :label="queryParam" v-model="queryParamValues[index].value" />
                    </div>
                </div>
                <br>
                <div class="evoliz-request-table">
                    <q-btn @click="submitRequest" color="secondary">Envoyer la requête</q-btn>
                </div>
            </div>
            <div v-if="requestResponse">
                <recursive-display :data="requestResponse" />
                <div v-if="pagination">
                    <button @click="changePage('previous')" :disabled="!pagination.prev">Previous</button>
                    <button @click="changePage('next')" :disabled="!pagination.next">Next</button>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import { ref, onMounted, computed, watch } from 'vue'
import axios from 'axios'
import RecursiveDisplay from '../components/RecursiveDisplay.vue'
import evolizRequests from "../assets/evolizRequests.json";
import { useStore } from "vuex";
import CreateRecoveryTemplates from '../components/CreateRecoveryTemplates.vue';
import UpdateTemplates from '../components/UpdateTemplates.vue';
export default {
    name: 'Evoliz',
    components: {
        RecursiveDisplay,
        CreateRecoveryTemplates,
        UpdateTemplates
    },
    setup() {
        const store = useStore();
        const requestsParams = ref(evolizRequests)
        const companies = ref([]);
        const companySelected = computed(() => store.state.companySelected)
        const evolizId = ref(null);
        const requests = ref([]);
        const requestSelected = ref(null);
        const params = ref([]);
        const paramsValues = ref([]);
        const queryParameters = ref([]);
        const queryParamValues = ref([]);
        const requestBody = ref([]);
        const requestResponse = ref(null);
        const pagination = ref({});
        const message = ref(null);
        const messageType = ref(null);
        const page = ref(1);

        const getDatas = async () => {
            evolizId.value = companySelected.value.evoliz_id;
            await getRequests();
        }

        const requestHandleSelect = async (value) => {
            paramsValues.value = [];
            queryParamValues.value = [];
            queryParameters.value = [];
            params.value = [];
            requestSelected.value = value;
            let request = value.path;
            //Appeler la fonction qui va preparer la requete
            prepareRequest(value);
        }

        //Fonction pour preparer la requete
        const prepareRequest = async (value) => {

            //Récupérer les path paramètres de la requête dans l'url entre {}
            let pathParams = value.path.match(/{(.*?)}/g);
            if (pathParams) {
                //On formate les path paramètres pour les rendre utilisables
                pathParams = pathParams.map(param => param.replace(/{|}/g, ''));
                //On affiche les paramètres de la requête dans l'interface
                params.value = pathParams;
                //On initialise les path paramètres de la requête
                paramsValues.value = pathParams.map(param => {
                    return {
                        name: param,
                        value: ''
                    }
                });
            }

            //On récupère les query paramètres de la requête si c'est une requete de type get
            if (determineRequestType(value.type_label) == 'get') {
                var queryParams = requestsParams.value.filter(r => r.id == value.id);

                if (queryParams[0]) {
                    queryParameters.value = Object.keys(queryParams[0].queryParameters);
                    //On initialise les query paramètres de la requête
                    queryParamValues.value = queryParameters.value.map(param => {
                        return {
                            name: param,
                            value: ''
                        }
                    });
                }
            } else {
                var requestBodyParams = requestsParams.value.filter(r => r.id == value.id);
                if (requestBodyParams[0]) {
                    requestBody.value = Object.keys(requestBodyParams[0].requestBody);
                    //On initialise les query paramètres de la requête
                    requestBody.value = requestBody.value.map(param => {
                        return {
                            name: param,
                            value: ''
                        }
                    });
                }

            }

        }

        const submitRequest = async () => {
            try {
                //Url de la requête
                let url = `${requestSelected.value.path}`;
                //On récupère les paramètres path de la requête
                let pathParams = paramsValues.value;
                //On les remplace dans l'url
                pathParams.forEach(param => {
                    url = url.replace(`{${param.name}}`, param.value);
                })
                //On récupère les paramètres query de la requête
                let queryParams = queryParamValues.value;
                //On construit l'url de la requête
                url = `${url}?${queryParams.filter(param => param.value).map(param => `${param.name}=${param.value}`).join('&')}`;
                url += `&page=${page.value}`;
                let requestType = determineRequestType(requestSelected.value.type_label);
                let response;
                let postBody = {
                    url: url,
                    type: requestType,
                };

                //On envoie la requête à l'API
                response = await axios.post(`${process.env.VUE_APP_BASE_URL}/evolizApiRequest/${companySelected.value.id}`, postBody)
                if (response.data.data) {
                    requestResponse.value = response.data.data;
                    pagination.value = response.data.links;
                } else {
                    requestResponse.value = response.data;
                }
            } catch (error) {
                console.log(error)
            }
        }

        const getCompanies = async () => {
            try {
                let url = `${process.env.VUE_APP_BASE_URL}/companies`;
                const response = await axios.get(url);
                companies.value = response.data;
            } catch (error) {
                console.error(error);
            }
        }

        const getRequests = async () => {
            try {
                let url = `${process.env.VUE_APP_BASE_URL}/evolizApiRequests`;
                const response = await axios.get(url);
                requests.value = response.data;
            } catch (error) {
                console.error(error);
            }
        }

        const changePage = async (value) => {
            try {
                if (value == 'next') {
                    page.value = page.value + 1;
                } else if (value == 'previous') {
                    page.value = page.value - 1;
                }
                submitRequest();
            } catch (error) {
                console.error(error);
            }
        }

        const copyMailTemplates = async () => {
            try {
                const response = await axios.post(`${process.env.VUE_APP_BASE_URL}/copyMailTemplates`, {
                    evolizCompanyId: companySelected.value.evoliz_id
                });
                alert('Les modèles de mail ont été copiés avec succès.');
            } catch (error) {
                console.error('Erreur lors de la copie des modèles de mail:', error);
                alert('Une erreur est survenue lors de la copie des modèles de mail.');
            }
        }

        //Fonction qui recupere le type_label de la requete et qui determine le type de requete (post get etc)
        const determineRequestType = (type_label) => {
            //Si le type_label contient le mot "post" alors c'est une requete de type post
            if (type_label.includes('POST')) {
                return 'post';
            }
            //Si le type_label contient le mot "get" alors c'est une requete de type get
            if (type_label.includes('GET')) {
                return 'get';
            }
            //Si le type_label contient le mot "put" alors c'est une requete de type put
            if (type_label.includes('PUT')) {
                return 'put';
            }
            //Si le type_label contient le mot "delete" alors c'est une requete de type delete
            if (type_label.includes('DELETE')) {
                return 'delete';
            }
            //Si le type_label contient le mot "patch" alors c'est une requete de type patch
            if (type_label.includes('PATCH')) {
                return 'patch';
            }
        }

        watch(() => companySelected.value, async () => {
            try {
                getDatas();
            } catch (error) {
                console.log(error);
            }
        });

        onMounted(() => {
            getCompanies();
        })
        return {
            companies,
            companySelected,
            getDatas,
            evolizId,
            requests,
            requestSelected,
            requestHandleSelect,
            params,
            paramsValues,
            queryParameters,
            queryParamValues,
            submitRequest,
            requestResponse,
            pagination,
            changePage,
            message,
            messageType,
            copyMailTemplates // Ajouter cette fonction dans le return pour l'utiliser dans le template
        }
    }
}
</script>

<style scoped>
.evoliz-container {
    margin-left: 20px;
}

.evoliz-requests {
    display: flex;
    justify-content: flex-start;
}

.evoliz-parameters {
    flex-basis: 40%;
    padding: 1%;
}

.evoliz-request-table {
    flex-basis: 60%;
}

.evoliz-parametrage {
    margin: 1%;
}

.evoliz-parametrage-buttons {
    display: flex;
    flex-direction: rows;
    justify-content: space-around;
}
</style>
