<template>

  <div class="addCompanyContainer">
    <h3 style="text-align:center; font-size:25px"><b>AJOUTER UN NOUVEAU FOURNISSEUR</b></h3>
    <div class="form">
        <q-input color="orange-1-12" v-model="siret" label="Renseignez le SIRET" :rules="siretRules">
        <template v-slot:prepend>
          <q-icon name="business" />
        </template>
      </q-input>
      <br>
      <p v-show="societyFound" style="color:red;">La société n'a pas été trouvé</p>
      <q-btn color="primary" label="Envoyer" @click="sendSiren"/>
    </div>
    <br>
    <hr>
    <div   class="validate">
      <q-input dense  v-model="account" label="Compte" />
      <q-input dense  v-model="legalFormOrCivility" label="Forme juridique ou civilité" />
      <q-input dense  v-model="name" label="Dénomination sociale" />
      <q-input dense  v-model="otherName" label="Autre nom" />
      <q-input dense  v-model="siret" label="SIRET ou NIF" />
      <q-input dense  v-model="declaredMainActivity" label="Activité principale" />
      <q-input dense  v-model="apeCode" label="Code APE" />
      <q-input dense  v-model="address" label="Adresse" />
      <q-input  dense  v-model="address2" label="Complément d'adresse" />
      <q-input dense  v-model="postal" label="Code postal" />
      <q-input dense  v-model="city" label="Ville" />
      <q-input dense  v-model="phone" label="Télephone" />
      <q-input dense  v-model="mail" label="Email" />
      <q-input dense  v-model="website" label="Site web" />
      <q-input dense  v-model="contact" label="Contact" />
      <q-input dense  v-model="comment" label="Commentaire" />
      <br>
      <q-btn color="secondary" label="Valider" @click="validate"/>
    </div>

    <div  class="banners">
      <q-banner v-if="saved" class="bg-secondary text-white">
      Données enregistrées
    </q-banner>
    <q-banner v-if="errorSaved" class="text-white bg-red">
      Erreur pendant l'enregistrement
    </q-banner>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import axios from "axios";
import {useStore} from "vuex";
export default {
  setup() {
    const store = useStore();
    let account = ref(null);
    let legalFormOrCivility = ref(null);
    let saved = ref(null);
    let name = ref(null);
    let otherName = ref(null);
    let societyFound = ref(null);
    let siret = ref(null);
    let declaredMainActivity = ref(null);
    let apeCode = ref(null);
    let address = ref(null);
    let address2 = ref(null);
    let postal = ref(null);
    let city = ref(null);
    let phone = ref(null);
    let mail = ref(null);
    let website = ref(null);
    let contact = ref(null);
    let comment = ref(null);
    let errorSaved = ref(false);
    const sendSiren = async () => {
      try {
        societyFound.value = false;
        let url = `${process.env.VUE_APP_BASE_URL}/companyInfos/` + siret.value;
        let response = await axios.get(url);
        let infos = response.data;
        name.value= infos.result.organization.name
        address.value = infos.result.organization.address.street
        address2.value = infos.result.organization.address.postal_address_additional_name
        postal.value = infos.result.organization.address.postal_code
        city.value = infos.result.organization.address.city
        apeCode.value = infos.result.organization.activity.ape_code
        legalFormOrCivility.value = infos.result.organization.legal.name
        declaredMainActivity.value  = infos.result.organization.activity.corporate_object
        website.value = infos.result.web_infos.website_url

      } catch (error) {
        societyFound.value = true;
        console.log(error);
      }
    };

    const handleAddContact = () => {};

    const validate = async () => {
      try {
        let postBody = {
          account: account.value,
          name: name.value,
          legalFormOrCivility: legalFormOrCivility.value,
          otherName: otherName.value,
          siret: siret.value,
          declaredMainActivity: declaredMainActivity.value,
          apeCode: apeCode.value,
          address: address.value,
          address2: address2.value,
          postal: postal.value,
          city: city.value,
          phone: phone.value,
          mail: mail.value,
          website: website.value,
          contact: contact.value,
          comment: comment.value,
          companyId: store.state.currentClient
        };
        let url = `${process.env.VUE_APP_BASE_URL}/stakeholder/`;

        let response = await axios.post(url, postBody);
        //infos.value = response.data
        saved.value = true;
        setTimeout(() => {
          saved.value = false;
        }, 1000);
      } catch (error) {
        console.log(error);
        errorSaved.value = true;
        setTimeout(() => {
          errorSaved.value = false;
        }, 1000);
      }
    };
    return {
      account,
      legalFormOrCivility,
      name,
      otherName,
      siret,
      declaredMainActivity,
      apeCode,
      address,
      address2,
      postal,
      city,
      phone,
      mail,
      website,
      contact,
      comment,
      validate,
      handleAddContact,
      sendSiren,
    };
  },
};
</script>

<style>
.addCompanyContainer {
  width: 70%;
  margin: auto;
}
.form {
  width: auto;
  margin: auto;
}

.banners {
  width: 30%;
  position: absolute;
  top: 50%;
  left: 40%;
  margin: auto;
}

div .q-field__messages {
  color: #f44336 !important;
}
.q-input .text-negative {
  color: #f44336 !important;
}
.q-field--error .q-field__bottom {
  color: #f44336 !important;
}
</style>