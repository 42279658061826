<template>
    <h2 class="text-2xl font-bold text-[#F7287B] p-2">ArchiGED</h2>
    <div class="w-full h-full p-4 bg-gray-100 flex flex-col items-center">

        <div class="w-full h-full" v-if="companySelected != '' && companyMainFoldersIframes">
            <!-- Sélection du dossier -->
            <div class="flex flex-row items-center justify-between">
                <div class="mb-4 w-full max-w-lg text-[#7209B7]">
                    <label for="folder-select" class="block font-medium mb-2">Sélectionnez un
                        dossier</label>
                    <select v-if="companyMainFoldersIframes" v-model="selectedFolderUrl" id="folder-select"
                        class="w-full border border-gray-300 rounded-md p-2 bg-white  shadow-sm focus:ring focus:ring-[#7209B7] focus:outline-none transition duration-150 ease-in-out">
                        <option value="" disabled selected>Sélectionnez un dossier...</option>
                        <option v-for="folder in companyMainFoldersIframes" :key="folder.folder_name"
                            :value="folder.folder_url">
                            {{ folder.folder_name }}
                        </option>
                    </select>
                </div>
                <!-- Boutons d'actions -->
                <div class="flex space-x-4 mt-4">
                    <button @click="openUploadModal"
                        class="bg-green-500 text-white px-4 py-2 rounded-lg shadow hover:bg-green-600 transition">
                        Télécharger un fichier
                    </button>
                    <button @click="openCreateFolderModal"
                        class="bg-[#7209B7] text-white px-4 py-2 rounded-lg shadow hover:bg-[#4E0668] transition">
                        Créer un dossier
                    </button>
                    <a href="https://workdrive.zoho.eu/home" target="_blank"
                        class="bg-gray-500 text-white px-4 py-2 rounded-lg shadow hover:bg-gray-600 transition">
                        Plus d'options
                    </a>
                </div>
            </div>
            <!-- Avertissement -->
            <div class="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-1 mb-2 text-s" role="alert">
                <p>Attention: Cette interface est un mode de prévisualisation et ne permet pas d'effectuer toutes les
                    actions. Pour
                    une fonctionnalité complète, veuillez utiliser l'option "Plus d'options".</p>
            </div>

            <!-- Affichage de l'iframe si un dossier est sélectionné -->
            <div v-if="selectedFolderUrl" class="w-full h-full">
                <iframe :src="`${iframeSrc}`" scrolling="no" frameborder="0" allowfullscreen="true"
                    class="w-full h-full rounded-lg shadow-lg border-none"></iframe>
            </div>
            <!-- Modals -->
            <UploadFileModal v-if="showUploadModal" :show="showUploadModal" :folderSelected="selectedFolderUrl"
                @close="closeUploadModal" />
            <CreateFolderModal v-if="showCreateFolderModal" :folderSelected="selectedFolderUrl"
                @close="closeCreateFolderModal" />
        </div>
        <div v-else>
            <p class="text-gray-500">Aucune entreprise sélectionnée ou pas de dossier disponible.</p>
        </div>
    </div>
</template>

<script>
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import axios from 'axios';
import ZohoJson from '@/assets/zoho.json';
import UploadFileModal from '@/components/UploadFileModal.vue';
import CreateFolderModal from '@/components/CreateFolderModal.vue';

export default {
    components: {
        UploadFileModal,
        CreateFolderModal
    },
    setup() {
        const store = useStore();
        const selectedFolderUrl = ref(null);  // Stocke l'URL du dossier sélectionné
        const showUploadModal = ref(false);
        const showCreateFolderModal = ref(false);
        const companyMainFoldersIframes = ref(null);

        // Récupère l'entreprise sélectionnée depuis le store
        const companySelected = computed(() => store.state.companySelected);

        const fetchCompanyMainFoldersIframes = async () => {
            if (companySelected.value) {
                const mainFolders = await getMainFolders();
                console.log(mainFolders);
                if (mainFolders) {
                    const iframes = mainFolders.map(folder => ({
                        folder_name: folder.attributes.name,
                        folder_url: `<iframe src=\"https://workdrive.zohopublic.eu/embed/${folder.id}?toolbar=true&layout=grid&appearance=light&themecolor=green\" scrolling=\"no\" frameborder=\"0\" allowfullscreen=true width=\"800\" height=\"520\" title=\"Code incorporé\" ></iframe>`
                    }));
                    console.log(iframes);
                    companyMainFoldersIframes.value = iframes;
                } else {
                    companyMainFoldersIframes.value = null;
                }
            } else {
                companyMainFoldersIframes.value = null;
            }
        };

        // Génère l'URL de l'iframe (extrait l'URL de l'iframe depuis le folder_url)
        const iframeSrc = computed(() => {
            if (selectedFolderUrl.value) {
                return selectedFolderUrl.value.split('src="')[1].split('"')[0];
            }
            return null;
        });

        const openUploadModal = () => {
            showUploadModal.value = true;
        };

        const closeUploadModal = () => {
            showUploadModal.value = false;
        };

        const openCreateFolderModal = () => {
            showCreateFolderModal.value = true;
        };

        const closeCreateFolderModal = () => {
            showCreateFolderModal.value = false;
        };

        const getMainFolders = async () => {
            try {
                // Récupération du dossier principal de l'entreprise
                const response = await axios.get(`${process.env.VUE_APP_BASE_URL}/companyFolder/${companySelected.value.company_code}`);
                // Récupération des dossiers principaux
                const mainFolders = await axios.get(`${process.env.VUE_APP_BASE_URL}/zohoSubFolders/${response.data.companyFolderId}`);
                return mainFolders.data;
            } catch (error) {
                console.error('Erreur lors de la récupération des dossiers principaux:', error);
            }
        };


        // Surveiller les changements de companySelected
        watch(companySelected, () => {
            fetchCompanyMainFoldersIframes();
        }, { immediate: true });

        return {
            companyMainFoldersIframes,
            companySelected,
            selectedFolderUrl,
            iframeSrc,
            showUploadModal,
            showCreateFolderModal,
            openUploadModal,
            openCreateFolderModal,
            closeUploadModal,
            closeCreateFolderModal
        };
    },
};
</script>
