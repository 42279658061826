import { createWebHistory, createRouter } from "vue-router";
import Subfolder from "@/components/Subfolder.vue";
import Preview from "@/views/Preview.vue";
import AddCompany from "@/components/AddCompany.vue";
import Partners from "@/views/Partners.vue";
import Mail from "@/views/Mail.vue";
import Login from "@/views/Login.vue";
import Home from "@/views/Home.vue";
import IaPreview from "@/views/IaPreview.vue"
import ZohoLogin from "@/views/ZohoLogin.vue"
import WorkForce from "@/views/WorkForce.vue"
import File from "@/views/File.vue"
import Evoliz from "@/views/Evoliz.vue"
import Ged from "../views/Ged.vue";
import Stock from "../views/Stock.vue";
import IaPreview2 from "../views/IaPreview2.vue";
import store from "../store";  
import { getAuth, onAuthStateChanged } from "firebase/auth";
import ArchiPilot from "@/archiPilot/views/ArchiPilot.vue";
import ArchiPilotClient from "@/archiPilot/components/ArchiPilotClient.vue"
import InterPage from "@/views/InterPage.vue"
import ArchiGed from "@/views/ArchiGed.vue"
import ArchiFec from "@/views/ArchiFec.vue"
const routes = [
  {
    path: "/",
    name: "Home",
    component: InterPage,
    meta: { requiresAuth: true }


  },
  {
    path: "/ged",
    name: "GED",
    component: Ged,
    meta: { requiresAuth: true }


  },
  {
    path: "/folder/:id",
    name: "Subfolder",
    component: Subfolder,
    meta: { requiresAuth: true }

  },
  {
    path: "/preview/:name",
    name : "Preview",
    component : Preview,
    meta: { requiresAuth: true }

  },
  
  {
    path: "/partners",
    name : "Parnters",
    component : Partners,
    meta: { requiresAuth: true }

  },
  {
    path: "/mail",
    name : "Mail",
    component : Mail,
    meta: { requiresAuth: true }

  },
  {
    path: "/login",
    name : "Login",
    component : Login,

  },
  {
    path: '/iapreview',
    name: 'IaPreview',
    component: IaPreview,
    meta: { requiresAuth: true }

  },
  {
    path: '/iapreview2',
    name: 'IaPreview2',
    component: IaPreview2,
    meta: { requiresAuth: true }

  },
  {
    path: '/zoho',
    name: 'Zoho',
    component: ZohoLogin,
    meta: { requiresAuth: true }

  },
  {
    path: '/workforce',
    name: 'WorkForce',
    component: WorkForce,
    meta: { requiresAuth: true }

  },
  {
    path: '/file',
    name: 'File',
    component: File,
    meta: { requiresAuth: true }

  },
  
  {
    path: '/stock',
    name: 'Stock',
    component: Stock,
    meta: { requiresAuth: true }

  },
  
  {
    path: "/archiPilot",
    name: "ArchiPilot",
    component: ArchiPilot,
    meta: { requiresAuth: true },
    children: [
      {
        path: 'archiPilotClient',
        name: 'ArchiPilotClient',
        component: ArchiPilotClient,
        meta: { requiresAuth: true }
    
      },
      {
        path: "addCompany",
        name : "AddCompany",
        component : AddCompany,
        meta: { requiresAuth: true }
    
      },
      {
        path: 'param',
        name: 'Param',
        component: Evoliz,
        meta: { requiresAuth: true }
    
      },
    ]

  },
  {
    path: '/interPage',
    name: 'InterPage',
    component: InterPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/archiGed',
    name: 'ArchiGed',
    component: ArchiGed,
    meta: { requiresAuth: true }

  },
  {
    path: '/archiFec',
    name: 'ArchiFec',
    component: ArchiFec,
    meta: { requiresAuth: true }

  },

  

];

const router = createRouter({
  history: createWebHistory(),
  routes,
});


// Fonction pour vérifier si l'utilisateur est authentifié avec Firebase
const checkAuth = () => {
  const auth = getAuth();
  return new Promise((resolve, reject) => {
    onAuthStateChanged(auth, user => {
      console.log(user ? "Utilisateur authentifié" : "Utilisateur non authentifié"); // Log de l'état d'authentification
      resolve(!!user);
    });
  });
};

// Gérer les redirections et l'authentification
router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const isAuthenticated = await checkAuth(); // Vérifie si l'utilisateur est authentifié

  console.log(`Tentative d'accès à ${to.name}: ${requiresAuth ? "nécessite une authentification" : "pas d'authentification requise"}`); // Log de la tentative d'accès
  console.log('Authentification:', isAuthenticated); // Log de l'état d'authentification
  if (requiresAuth && !isAuthenticated) {
    // Redirige vers la page de login si l'utilisateur n'est pas connecté
    console.log("Redirection vers la page de login"); // Log de redirection
    next({ name: 'Login' });
  } else {
    // Autorise l'accès à la route
    console.log("Accès autorisé"); // Log d'accès autorisé
    next();
  }
});


export default router;