<template>
    <div>
        <h2 class="text-2xl font-bold text-[#F7287B] p-2">FacturEase</h2>
        <FilterAndSort @invoiceSelected='handleSelect' @isTemp="changeIsTemp" />
        <div class="container">
            <div class="preview" v-if="!loading">
                <div class="sticky-wrapper">
                    <embed v-if="pdfUrl" :src="pdfUrl" width="90%" height="700px">
                    <br />
                </div>
            </div>
            <div v-else>
                <q-spinner color="primary" size="3em" :thickness="2" style="margin:auto" />
            </div>

            <div class="form" v-if="(invoiceDatas.document_type && !loading) || invoiceDatas.document_type == ''">
                <div>
                    <q-btn label="Supprimer la facture" color="negative" @click="confirmDelete()" class="q-mr-sm" />
                    <q-btn label="HT -> TTC" color="primary" @click="convertHtToTtc" class="q-mr-sm" />
                    <q-btn label="TTC -> HT" color="secondary" @click="convertTtcToHt" />
                </div>
                <!--************ Affichage des données de la facture **********-->
                <div class="invoicesDatasForm">
                    <h5>Elements généraux</h5>
                    <form class="form-container">
                        <div class="form-row">
                            <label>Type de document :
                                <q-icon name="help_outline" color="primary"
                                    v-tippy="{ theme: 'light', placement: 'top', content: 'Cette facture est une facture' }" />
                            </label>
                            <select v-model="invoiceDatas.document_type" class="form-input">
                                <option value="facture">Facture</option>
                                <option value="avoir">Avoir</option>
                            </select>
                        </div>
                        <div class="form-row">
                            <label>Date :</label>
                            <input v-model="invoiceDatas.invoice_date" class="form-input" />
                        </div>
                        <div class="form-row">
                            <label>Date d'échéance :</label>
                            <input v-model="invoiceDatas.deadline" class="form-input" />
                        </div>
                        <div class="form-row">
                            <label>Numéro de facture :</label>
                            <input v-model="invoiceDatas.invoice_number" class="form-input" />
                        </div>
                        <div class="form-row">
                            <label>Nom du fournisseur (Sur facture) :</label>
                            <input v-model="invoiceDatas.supplier_name" class="form-input" />
                        </div>
                        <div class="form-row">
                            <label>Nom du fournisseur (Siret) :</label>
                            <input v-model="stakeholderInfos.name" class="form-input" />
                        </div>
                        <div class="form-row">
                            <label>Compte du fournisseur :</label>
                            <select v-model="invoiceDatas.stakeholder_account"
                                class="form-input supplier-account-select" @update:model-value="updateStakeholderAccount">
                                <option v-for="stakeholder in companyStakeholder" :key="stakeholder.id"
                                    :value="stakeholder.account">
                                    {{ stakeholder.account }} - {{ stakeholder.description }}
                                </option>
                            </select>
                        </div>
                        <div class="form-row">
                            <label>Montant total TTC :</label>
                            <input v-model.number="invoiceDatas.total_ttc" class="form-input" />
                        </div>
                        <div class="form-row">
                            <label>Enregistrement :</label>
                            <select v-model="registrationValue['total_ttc']" class="form-input">
                                <option value="débit">Débit</option>
                                <option value="crédit">Crédit</option>
                            </select>
                        </div>
                        <div class="form-row">
                            <label>Option de débit TVA :</label>
                            <select v-model="invoiceDatas.tva_debit_option" class="form-input">
                                <option value="true">Vrai</option>
                                <option value="false">Faux</option>
                            </select>
                        </div>
                        <div class="form-row">
                            <label>Autoliquidation :</label>
                            <select v-model="invoiceDatas.invoice_mention" class="form-input">
                                <option value="true">Vrai</option>
                                <option value="false">Faux</option>
                            </select>
                        </div>
                        <div class="form-row">
                            <label>Moyen de paiement :</label>
                            <select v-model="invoicePaymentMethod" class="form-input">
                                <option value="">Sélectionnez un moyen de paiement</option>
                                <option v-for="method in paymentMethods" :key="method.label" :value="method.label">
                                    {{ method.label }}
                                </option>
                            </select>
                        </div>
                        <div class="form-row">
                            <label>Type de fournisseur :</label>
                            <select v-model="supplierType" class="form-input">
                                <option value="Classique">Classique</option>
                                <option value="MarketPlace">MarketPlace</option>
                            </select>
                        </div>
                        <div class="form-row">
                            <label>Nom de la MarketPlace :</label>
                            <select v-model="marketPlaceName" class="form-input">
                                <option value="">Sélectionnez une marketplace</option>
                                <option v-for="marketplace in marketplaces" :key="marketplace" :value="marketplace">
                                    {{ marketplace }}
                                </option>
                            </select>
                        </div>
                        <div class="form-row">
                            <label>Pays :</label>
                            <input v-model="country" class="form-input" />
                        </div>
                    </form>

                </div>
                <!--************ Affichage de la vérification de la facture **********-->
                <div :class="['verificationResult', isScrolledPast ? 'fixed-rectangle' : 'hidden-rectangle']">
                    <div v-if="verifyEntireInvoice().isBalanced" class="icon success">
                        <q-icon color="green" name="check_circle" />
                        La facture est équilibrée.
                        <ul>
                            <li>Total débit : {{ verifyEntireInvoice().totalDebit }}</li>
                            <li>Total crédit : {{ verifyEntireInvoice().totalCredit }}</li>
                            <li>Montant total de la facture : {{ verifyEntireInvoice().invoiceTotal }}</li>
                            <li>Écart constaté : {{ verifyEntireInvoice().discrepancy }}</li>
                        </ul>
                    </div>

                    <div v-else class="icon error">
                        <q-icon color="red" name="error" /> Il y a une incohérence dans les écritures comptables.
                        <ul>
                            <li>Total débit : {{ verifyEntireInvoice().totalDebit }}</li>
                            <li>Total crédit : {{ verifyEntireInvoice().totalCredit }}</li>
                            <li>Montant total de la facture : {{ verifyEntireInvoice().invoiceTotal }}</li>
                            <li>Écart constaté : {{ verifyEntireInvoice().discrepancy }}</li>
                        </ul>
                    </div>
                </div>
                <!--************ Affichage de la TVA **********-->
                <div v-if="tvaAccounts && companySelected.tva_regime !== 'franchise'">
                    <h5>TVA</h5>
                    <div class="tvaForm">
                        <div>
                            <div>
                                <div><span>TVA 20% :</span> <input v-model="tvaAccounts[0]['tva_20']" /></div>
                                <div><span>Compte 1</span> <input v-model="tvaAccounts[0]['account1']" /></div>
                                <div><span>Compte 2</span> <input v-model="tvaAccounts[0]['account2']" /></div>
                            </div>
                            <div>
                                <div><span>Enregistrement compte 1</span> <input
                                        v-model="tvaAccounts[0]['registration1']" /></div>
                                <div><span>Enregistrement compte 2</span> <input
                                        v-model="tvaAccounts[0]['registration2']" /></div>
                            </div>
                        </div>
                        <hr>
                        <div>
                            <div>
                                <div><span>TVA 13% : </span><input v-model="tvaAccounts[1]['tva_13']" /></div>
                                <div><span>Compte 1</span> <input v-model="tvaAccounts[1]['account1']" /></div>
                                <div><span>Compte 2</span> <input v-model="tvaAccounts[1]['account2']" /></div>
                            </div>
                            <div>
                                <div><span>Enregistrement compte 1</span> <input
                                        v-model="tvaAccounts[1]['registration1']" /></div>
                                <div><span>Enregistrement compte 2</span> <input
                                        v-model="tvaAccounts[1]['registration2']" /></div>
                            </div>
                        </div>
                        <hr>
                        <div>
                            <div>
                                <div><span>TVA 10% : </span><input v-model="tvaAccounts[2]['tva_10']" /></div>
                                <div><span>Compte 1</span> <input v-model="tvaAccounts[2]['account1']" /></div>
                                <div><span>Compte 2</span> <input v-model="tvaAccounts[2]['account2']" /></div>
                            </div>
                            <div>
                                <div>
                                    <div><span>Enregistrement compte 1</span> <input
                                            v-model="tvaAccounts[2]['registration1']" /></div>
                                    <div><span>Enregistrement compte 2</span> <input
                                            v-model="tvaAccounts[2]['registration2']" /></div>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div>
                            <div>
                                <div><span>TVA 5.5% : </span><input v-model="tvaAccounts[3]['tva_5_5']" /></div>
                                <div><span>Compte 1</span> <input v-model="tvaAccounts[3]['account1']" /></div>
                                <div><span>Compte 2</span> <input v-model="tvaAccounts[3]['account2']" /></div>
                            </div>
                            <div>
                                <div>
                                    <div><span>Enregistrement compte 1</span> <input
                                            v-model="tvaAccounts[4]['registration1']" /></div>
                                    <div><span>Enregistrement compte 2</span> <input
                                            v-model="tvaAccounts[4]['registration2']" /></div>
                                </div>
                            </div>

                        </div>
                        <hr>
                        <div>
                            <div>
                                <div><span>TVA 2.1% : </span><input v-model="tvaAccounts[4]['tva_2_10']" /></div>
                                <div><span>Compte 1</span> <input v-model="tvaAccounts[4]['account1']" /></div>
                                <div><span>Compte 2</span> <input v-model="tvaAccounts[4]['account2']" /></div>
                            </div>
                            <div>
                                <div>
                                    <div><span>Enregistrement compte 1</span> <input
                                            v-model="tvaAccounts[5]['registration1']" /></div>
                                    <div><span>Enregistrement compte 2</span> <input
                                            v-model="tvaAccounts[5]['registration2']" /></div>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div>
                            <div>
                                <div><span>TVA 0% : </span><input v-model="tvaAccounts[5]['tva_0']" /></div>
                            </div>
                            <div>
                                <div>
                                    <div><span>Enregistrement compte 1</span> <input
                                            v-model="tvaAccounts[6]['registration1']" /></div>
                                    <div><span>Enregistrement compte 2</span> <input
                                            v-model="tvaAccounts[6]['registration2']" /></div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
                <!--************ Affichage des produits **********-->
                <div class="productsForm">
                    <h5>Produits</h5>
                    <div v-if="products.length == 0">
                        <span>Il n'y a aucun produit trouvé sur cette facture</span>
                        <div><q-btn flat color="secondary" icon="add_circle_outline" @click="addProduct()" /></div>
                    </div>

                    <div class="productsList" v-for="(product, index) in products" :key="index">
                        <div>
                            <q-toggle v-model="isOneImmo[index]" label="Immobilisation"
                                @update:model-value="handleOneImmo(product, isOneImmo[index])" />
                        </div>
                        <div class="form-group">
                            <label>Designation :</label>
                            <input type="text" v-model="product.designation" />
                        </div>
                        <div class="form-group">
                            <label for="unit">Unité :</label>
                            <select v-model="product.pack_unity" id="unit" class="form-control">
                                <option v-for="unit in units" :key="unit" :value="unit">
                                    {{ unit }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label>Nb dans pack :</label>
                            <input type="number" v-model.number="product.pack_number" />
                        </div>

                        <div class="form-group">
                            <label>Quantité :</label>
                            <input type="number" v-model.number="product.quantity" />
                        </div>

                        <div class="form-group" v-if="companySelected.tva_regime !== 'franchise'">
                            <label>Total HT :</label>
                            <input type="number" v-model.number="product.total_amount_ht"
                                :placeholder="companySelected.tva_regime === 'franchise' ? 'Non pertinent' : ''" />

                        </div>
                        <div class="form-group">
                            <label>Taux de TVA :</label>
                            <input type="number" v-model.number="product.tva_rate" />
                        </div>
                        <div class="form-group">
                            <label>Total TTC :</label>
                            <input type="number" v-model.number="product.total_amount_ttc"
                                :placeholder="companySelected.tva_regime === 'franchise' ? '' : 'Non pertinent'" />
                        </div>
                        <div class="form-group">
                            <label>Compte :</label>
                            <q-select v-model="product.account" :options="filteredAccounts" use-input input-debounce="0"
                                option-label="label" option-value="key" @filter="filterAccounts"
                                label="Choisir un compte" outlined filled dense emit-value class="custom-select" />
                            <q-btn flat color="primary" icon="playlist_add" @click="duplicateAccount(product)" />
                        </div>

                        <div v-if="isOneImmo[index]">
                            <label>Compte d'immobilisation :</label>
                            <input type="text" v-model="product.account" />
                        </div>
                        <div>
                            <span style="display: block ; width: 100%; text-align: center;">
                                {{
                                    accounts.find(account => account.key && product.account && account.key.substring(0, 8)
                                        ==
                                        product.account.substring(0, 8))?.value
                                }}
                            </span>
                        </div>

                        <div class="form-group">
                            <label>Enregistrement :</label>
                            <select v-model="product.registration">
                                <option value="crédit">Crédit</option>
                                <option value="débit">Débit</option>
                            </select>
                        </div>
                        <div>
                            <q-btn flat color="primary" label="Ajouter un nouveau compte d'achat"
                                @click="toggleNewAccountForm(product)" />
                            <div v-if="isAddingNewAccount">
                                <input type="text" v-model="newPurchaseAccount.account"
                                    placeholder="Numéro de compte" />
                                <input type="text" v-model="newPurchaseAccount.designation" placeholder="Désignation" />
                                <input type="text" v-model="newPurchaseAccount.description" placeholder="Description" />
                                <input type="text" v-model="newPurchaseAccount.tvaAccount" placeholder="Compte TVA" />
                                <div v-if="showTvaWarning" class="warning-message">
                                    Attention : Aucun compte de TVA actif détecté. Veuillez sélectionner un compte de
                                    TVA.
                                </div>
                                <q-btn flat color="secondary" label="Enregistrer" @click="saveNewPurchaseAccount"
                                    :loading="isSaving">
                                    <template v-slot:loading>
                                        <q-spinner-facebook />
                                    </template>
                                </q-btn>
                            </div>
                        <div class="message-container">
                            <p v-if="productMessage" :class="{ 'success-message': productMessageColor === 'green', 'error-message': productMessageColor === 'red' }">
                                {{ productMessage }}
                            </p>
                        </div>
                        </div>
                        <div>
                            <q-btn flat color="secondary" icon="add_circle_outline" @click="addProduct()" />
                            <q-btn flat style="color: #FF0080" icon="remove_circle_outline"
                                @click="removeProduct(product)" />
                        </div>
                        <!-- Vérification des écritures comptables avec icônes -->
                        <div class="verificationResult">
                            <span v-if="verifyAccountingEntries(product)" class="icon success"><q-icon color="green"
                                    name="check_circle" /></span>
                            <!-- Icône verte pour succès -->
                            <span v-else class="icon error"><q-icon color="red" name="error" /></span>
                            <!-- Icône rouge pour erreur -->
                            <span>{{ verifyAccountingEntries(product) ? 'Écritures équilibrées' : `Incohérence
                                dans
                                les écritures` }}</span>
                        </div>
                        <hr style="color: black; height: 2px; background-color: black; width: 100%;">
                    </div>
                </div>
                <!--************ Affichage des frais annèxes **********-->
                <div class="productsForm">
                    <h5>Frais accessoires</h5>
                    <div v-if="extraFees.length == 0">
                        <span>Il n'y a aucun frais trouvé sur cette facture</span>
                        <div><q-btn flat color="secondary" icon="add_circle_outline" @click="addProduct()" /></div>
                    </div>

                    <div class="productsList" v-for="(product, index) in extraFees" :key="index">
                        <div class="form-group">
                            <label>Designation :</label>
                            <input type="text" v-model="product.designation" />
                        </div>
                        <div class="form-group">
                            <label>Unité :</label>
                            <input type="text" v-model="product.pack_unity" />
                        </div>
                        <div class="form-group">
                            <label>Nb dans pack :</label>
                            <input type="number" v-model.number="product.pack_number" />
                        </div>

                        <div class="form-group">
                            <label>Quantité :</label>
                            <input type="number" v-model.number="product.quantity" />
                        </div>

                        <div class="form-group">
                            <label>Total HT :</label>
                            <input type="number" v-model.number="product.total_amount_ht" />
                        </div>
                        <div class="form-group">
                            <label>Taux de TVA :</label>
                            <input type="number" v-model.number="product.tva_rate" />
                        </div>
                        <div class="form-group">
                            <label>Total TTC :</label>
                            <input type="number" v-model.number="product.total_amount_ttc" />
                        </div>
                        <div class="form-group">
                            <label>Compte :</label>
                            <q-select v-model="product.account" :options="filteredAccounts" use-input input-debounce="0"
                                option-label="label" option-value="key" @filter="filterAccounts"
                                label="Choisir un compte" outlined filled dense emit-value class="custom-select" />
                            <q-btn flat color="primary" icon="playlist_add" @click="duplicateAccount(product)" />

                        </div>
                        <div>
                            <span style="display: block ; width: 100%; text-align: center;">
                                {{
                                    accounts.find(account => account.key && product.account && account.key.substring(0, 8)
                                        ==
                                        product.account.substring(0, 8))?.value
                                }}
                            </span>
                        </div>
                        <div class="form-group">
                            <label>Enregistrement :</label>
                            <input type="text" v-model="product.registration" />
                        </div>

                        <div>
                            <q-btn flat color="secondary" icon="add_circle_outline" @click="addExtraFees()" />
                            <q-btn flat style="color: #FF0080" icon="remove_circle_outline"
                                @click="removeExtraFees(product)" />
                        </div>
                        <!-- Vérification des écritures comptables avec icônes -->
                        <div class="verificationResult">
                            <span v-if="verifyAccountingEntries(product)" class="icon success"><q-icon color="green"
                                    name="check_circle" /></span>
                            <!-- Icône verte pour succès -->
                            <span v-else class="icon error"><q-icon color="red" name="error" /></span>
                            <!-- Icône rouge pour erreur -->
                            <span>{{ verifyAccountingEntries(product) ? 'Écritures équilibrées' : `Incohérence
                                dans
                                les écritures` }}</span>
                        </div>
                        <hr style="color: black; height: 2px; background-color: black; width: 100%;">
                    </div>
                </div>
                <!--************ Ecritures comptables **********-->
                <div class="table-container">
                    <h5>Écritures Comptables</h5>
                    <table>
                        <thead>
                            <tr>
                                <th>Code Journal</th>
                                <th>Libellé Journal</th>
                                <th>Numéro Écriture</th>
                                <th>Date Comptabilisation</th>
                                <th>Numéro Compte</th>
                                <th>Libellé Compte</th>
                                <th>Numéro Compte Auxiliaire</th>
                                <th>Libellé Compte Auxiliaire</th>
                                <th>Référence Pièce</th>
                                <th>Date Pièce</th>
                                <th>Libellé Écriture</th>
                                <th>Montant Débit</th>
                                <th>Montant Crédit</th>
                                <th>Lettrage</th>
                                <th>Date Lettrage</th>
                                <th>Date Validation</th>
                                <th>Montant en Devise</th>
                                <th>Identifiant Devise</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="entry in accountingEntriesComputed" :key="entry.numeroEcriture">
                                <td>{{ entry.codeJournal }}</td>
                                <td>{{ entry.libelleJournal }}</td>
                                <td>{{ entry.numeroEcriture }}</td>
                                <td>{{ entry.dateCompta }}</td>
                                <td>{{ entry.numeroCompte }}</td>
                                <td>{{ entry.libelleCompte }}</td>
                                <td>{{ entry.numeroCompteAux }}</td>
                                <td>{{ entry.libelleCompteAux }}</td>
                                <td>{{ entry.referencePiece }}</td>
                                <td>{{ entry.datePiece }}</td>
                                <td>{{ entry.libelleEcriture }}</td>
                                <td>{{ entry.montantDebit }}</td>
                                <td>{{ entry.montantCredit }}</td>
                                <td>{{ entry.lettrage }}</td>
                                <td>{{ entry.dateLettrage }}</td>
                                <td>{{ entry.dateValidation }}</td>
                                <td>{{ entry.montantDevise }}</td>
                                <td>{{ entry.idDevise }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="action-buttons">
                    <div class="button-group">
                        <q-btn color="secondary" @click="approveData" class="btn">Valider les données</q-btn>
                        <q-btn color="amber" @click="sendToEvoliz" class="btn">Envoyer dans Evoliz</q-btn>
                    </div>
                    <div v-if="isLoading" class="loading-spinner">
                        <q-spinner-box color="primary" size="2em" />
                        <q-tooltip :offset="[0, 8]">QSpinnerBox</q-tooltip>
                    </div>
                    <p v-if="showMessage" :style="{ color: messageColor }" class="confirmation-message">
                        {{ confirmationMessage }}
                    </p>
                    <br>
                    <span class="validated-invoices">
                        Vous avez validé {{ validatedInvoices.length }} factures aujourd'hui
                    </span>
                    <q-btn color="black" @click="downloadFec" :disable="!companySelected.evoliz_id"
                        :class="{ 'disabled-btn': !companySelected.evoliz_id }">
                        Télécharger le FEC des Achats - Accès Expert Comptable
                        <q-icon name="lock" v-if="!companySelected.evoliz_id" />
                    </q-btn>
                    <q-btn color="primary" @click="downloadFec2" :disable="!companySelected.evoliz_id"
                        :class="{ 'disabled-btn': !companySelected.evoliz_id }">
                        Télécharger le FEC - Nouvelle version
                        <q-icon name="lock" v-if="!companySelected.evoliz_id" />
                    </q-btn>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import FilterAndSort from '../components/FilterAndSort.vue'
import { ref, computed, watch, watchEffect, onMounted, onBeforeUnmount } from 'vue';
import axios from 'axios';
import { getInvoiceDatas, getStakeholderAccount, getCountry, getStakeholderInfos, getProducts, registration, getTvaAccount, replaceLastTwoCharsWithZero, getAllTvaAccountsAndAmounts, getIsDeposit, formatFecEntry, getValidatedInvoices } from '../utils/utils.js';
import { useStore } from 'vuex';
import { createFecEntry } from '../utils/accounting.js';
import { downloadFecFile } from '../utils/utils.js';
import accountsData from '../assets/pcg_2024_JSON.json';
import accountsData2 from '../assets/pcg_compte_2.json';
import tvaAccountsData from '../assets/pcg_tva_2024_JSON.json';
import { asyncComputed, computedAsync } from '@vueuse/core'
import Header2 from '@/components/Header2.vue';
import { createContext } from 'chart.js/helpers';

export default {
    components: {
        FilterAndSort,
        Header2
    },
    setup() {
        const store = useStore();
        const loading = ref(false);
        const pdfUrl = ref();
        const isTemp = ref(true);
        const companySelected = computed(() => store.state.companySelected);
        //Données de la facture
        const invoiceDatas = ref({});
        const stakeholderInfos = ref({});
        const registrationValue = ref({});
        const invoicePaymentMethod = ref('');
        const supplierType = ref('');
        const marketPlaceName = ref('');
        const country = ref('');
        const isImmo = ref(false);
        //Produits
        const products = ref([]);
        const isLoading = ref(false);
        //Validation des données
        const validatedInvoices = ref(0);
        const confirmationMessage = ref('');
        const messageColor = ref('');
        const showMessage = ref(false);
        const showTvaWarning = ref(false);
        const isSaving = ref(false);
        const fileName = ref('');
        const productMessage = ref('');
        const productMessageColor = ref('');
        // Liste des unités
        const units = ref([
            "Année",
            "Centaine",
            "Dizaine",
            "Forfait",
            "Gramme",
            "Groupe",
            "Hectare",
            "Hectolitre",
            "Heure",
            "Image",
            "Jour",
            "Kilogramme",
            "Kilomètre",
            "Kilomètre carré",
            "Litre",
            "Lot",
            "Mètre",
            "Mètre carré",
            "Mètre cube",
            "Mètre linéaire",
            "Millier",
            "Milligramme",
            "Millilitre",
            "Minute",
            "Mois",
            "Paire",
            "Personne",
            "Seconde",
            "Stère",
            "Tonne",
            "Unité"
        ]);

        //Liste des types de paiements
        const paymentMethods = ref([
            { label: 'autres' },
            { label: 'avoir' },
            { label: 'carte bancaire' },
            { label: 'chèque' },
            { label: 'chèque emploi service universel (CESU)' },
            { label: 'chèque vacances' },
            { label: 'contre-remboursement' },
            { label: 'espèces' },
            { label: 'lettre de change' },
            { label: 'payPal' },
            { label: 'prélèvement' },
            { label: 'traite' },
            { label: 'virement' }
        ]);

        //Liste des marketplaces
        const marketplaces = ref([
            "", "Amazon", "Ali Express", "Airbnb", "Alibaba", "Allegro", "Auchan", "Booking", "But", "BackMarket",
            "BHV", "Boulanger", "Bricoprive", "Bol", "C Discount", "Conforama", "Conrad", "Carrefour", "Camif",
            "Darty", "Delamaison", "Ebay", "Etsy", "Emag", "Eprice", "Fnac", "Flipkart", "Groupon", "GoSport",
            "Intermarche", "Leroy Merlin", "Leclerc", "LDLC", "Mano Mano", "MercadoLibre", "MaisonDuMonde", "MacWay",
            "Oui Sncf", "Ozon", "Pinduoduo", "La Poste", "ThePhoneHouse", "La Redoute", "RueDuCommerce", "Rakuten", "Retif",
            "Refurbed", "ShowroomPrive", "Shopee", "Sprinter", "Taobao", "Tmall", "Temu", "U balbi", "Vinted", "Veepee", "Wish",
            "Walmart", "WildBerries"
        ]);

        //Données liées à l'exercice
        const lastHA = ref();

        //********Fonctions**********

        //Fonction pour télécharger et afficher la facture
        const downloadPdf = async (fileName) => {
            try {
                console.log(`Début du téléchargement du PDF pour le fichier : ${fileName}`);
                let url = `${process.env.VUE_APP_BASE_URL}/imageFromScaleway/${fileName}`;
                let response = await axios.get(url, { responseType: 'blob' });
                const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
                const pdfUrl = URL.createObjectURL(pdfBlob);
                console.log('Téléchargement du PDF terminé');
                return pdfUrl;
            } catch (error) {
                console.error('Erreur lors du téléchargement du PDF :', error);
                throw error;  // Relancer l'erreur après l'avoir enregistrée
            }
        };

        //Fonction pour récupérer les données, lors de la seléction d'une facture
        const handleSelect = async (selected) => {
            try {
                console.log('Fichier sélectionné :');
                loading.value = true;
                fileName.value = selected.name;
                // Télécharger le PDF
                pdfUrl.value = await downloadPdf(selected.name);

                // Récupérer et traiter les données de la facture
                console.log('Récupération des données de la facture...');
                const invoiceData = await getInvoiceDatas(selected, isTemp.value);
                invoiceDatas.value = invoiceData;

                // Affecter les méthodes de paiement et autres informations
                invoicePaymentMethod.value = invoiceData.payment_method || '';
                supplierType.value = invoiceData.supplier_type || '';
                marketPlaceName.value = invoiceData.marketplace_name || '';

                // Récupérer le compte du tiers
                console.log('Récupération du compte du tiers...');
                invoiceDatas.value.stakeholder_account = await getStakeholderAccount(companySelected.value.id, invoiceDatas.value.stakeholder_id);

                // Récupérer les données du pays
                console.log('Récupération des données du pays...');
                country.value = await getCountry(invoiceDatas.value.stakeholder_id);

                // Récupérer les informations du tiers
                console.log('Récupération des informations du tiers...');
                stakeholderInfos.value = await getStakeholderInfos(invoiceDatas.value.stakeholder_id);


                // Récupérer et traiter les produits
                console.log('Récupération des produits...');
                products.value = await getProducts(selected, isTemp.value);

                //Gérer la fanchise de tva
                if (companySelected.value.tva_regime == 'franchise') {
                    products.value.map(product => {
                        product.tva_rate = 0;
                    })
                }
                // Mettre à jour le compte produit
                console.log('Mise à jour du compte produit...');
                updateProductAccount();

                //Récupérer les comptes fournisseurs
                console.log('Récupération des comptes fournisseurs...');
                await fetchCompanyStakeholder();

                // Mettre à jour les valeurs d'enregistrement
                console.log('Mise à jour des valeurs d\'enregistrement...');
                getRegistrationValues();
                addRegistrationToProducts();


                if (isTemp.value) {
                    // Récupérer les frais supplémentaires et les informations de dépôt si temporaire
                    console.log('Récupération des frais supplémentaires...');
                    await getExtraFees2();
                    console.log('Récupération des informations de dépôt...');
                    await getIsDeposit();
                }

                // Récupérer les factures validées
                console.log('Récupération des factures validées...');
                await getValidatedInvoices();

                //Ajouter les immobilisation
                addImmoToProducts();
                /*
                // Mettre à jour les comptes TVA
                console.log('Mise à jour des comptes TVA...');
                tvaAccounts.value = getTvaAccount(country.value, invoiceDatas.value, products.value);
                addRegistrationToTva();

                // Générer les écritures comptables
                console.log('Génération des écritures comptables...');
                getAccountingEntry2();

                // Générer les écritures de décaissement
                console.log('Génération des écritures de décaissement...');
                getDecaissementEntry();

                

                
                */
                //Gérer les immobilisation
                products.value.map(product => {
                    if (product.data && product.data.isImmo) {
                        handleOneImmo(product, true);
                    }
                })

                //Gestion des données de l'exercice
                await fetchAccountingExerciceData();

                loading.value = false;
                console.log('Processus terminé avec succès');
            } catch (error) {
                console.error('Erreur dans handleSelect :', error);
                loading.value = false; // Assurer que le chargement est réinitialisé en cas d'erreur
            }
        };


        // Gestion des produits
        const addImmoToProducts = () => {
            for (let index = 0; index < products.value.length; index++) {
                let product = products.value[index];
                // Vérifier si product.data est null ou indéfini
                if (!product.data) {
                    product.data = {};
                }

                // Vérifier si isImmo est null ou indéfini et le définir à false si nécessaire
                if (!product.data.hasOwnProperty('isImmo') || product.data.isImmo === null) {
                    product.data.isImmo = false;
                }

                // Définir isOneImmo pour chaque produit
                isOneImmo.value[index] = product.data.isImmo;
            }
        };

        const removeProduct = (product) => {

            var indexToDelete = -1;
            for (var i = 0; i < products.value.length; i++) {
                if (products.value[i].designation === product.designation) {
                    indexToDelete = i;
                    break;
                }
            }
            if (indexToDelete !== -1) {
                products.value.splice(indexToDelete, 1);
            }
        }

        const duplicateAccount = (product) => {
            const newAccount = product.account;
            products.value.forEach(p => {
                p.account = newAccount;
            });
        }

        const addProduct = () => {
            products.value.push({
                designation: "",
                pack_unity: "",
                pack_number: 0,
                stock_name: "",
                quantity: 1,
                tva_rate: 20,
                account: "60110000020",
                accountBis: "60110000000",
                total_amount_ht: 0,
                total_amount_ttc: 0,
                registration: "",
                unity: "",
            })
        }

        const updateProductAccount = () => {

            products.value.forEach((product, index) => {

                if (isOneImmo.value[index] == true) {
                    return;
                }
                if (!product.account) {
                    return;
                }

                let newAccountSuffix;
                if (product.tva_rate === 20) {
                    newAccountSuffix = '20';
                } else if (product.tva_rate === 13) {
                    newAccountSuffix = '13';
                } else if (product.tva_rate === 10) {
                    newAccountSuffix = '10';
                } else if (product.tva_rate === 5.5) {
                    newAccountSuffix = '55';
                } else if (product.tva_rate === 2.1) {
                    newAccountSuffix = '21';
                } else if (product.tva_rate === 0) {
                    newAccountSuffix = '00';
                }

                if (newAccountSuffix) {
                    product.account = product.account.slice(0, -2) + newAccountSuffix;
                }
            })
        }

        // Watcher pour chaque produit dans la liste des produits
        watchEffect(() => {
            products.value.forEach((product, index) => {
                watch(
                    () => product.account,
                    (newValue, oldValue) => {
                        if (newValue !== oldValue) {
                            updateProductAccount();
                        }
                    },
                    { deep: true }
                );
            });
        });




        //Gestion des comptes pcg pour les produits
        const search = ref('');
        const accounts = ref(
            [
                ...Object.entries(accountsData[0]).map(([key, value]) => ({ label: `${key} - ${value}`, key: key, value: value })),
                ...Object.entries(accountsData2[0]).map(([key, value]) => ({ label: `${key} - ${value}`, key: key, value: value }))
            ]
        );
        const filteredAccounts = computed(() => {
            if (search.value) {
                return accounts.value.filter((account) =>
                    Object.values(account).some((value) =>
                        value && value.toString().toLowerCase().includes(search.value.toLowerCase())
                    )
                );
            } else {
                return accounts.value;
            }
        });


        const fetchAccounts = async () => {
            try {

                if (!companySelected.value || !companySelected.value.evoliz_id) {
                    console.error("Evoliz ID not found in companySelected:", companySelected.value);
                    return;
                }

                const response = await axios.get(`${process.env.VUE_APP_BASE_URL}/comparePCG/${companySelected.value.evoliz_id}`);
                const accountsData = response.data.missingInCSV;
                

                // Ajout des nouveaux comptes aux comptes existants sans les remplacer
                accounts.value = [
                    ...accounts.value,
                    ...accountsData.map(account => ({
                        label: `${account.code} - ${account.labels}`,
                        key: account.code,
                        value: account.labels
                    }))
                ];
            } catch (error) {
                console.error('Erreur lors de la récupération des comptes:', error);
            }
        };

        const fetchSpecialAccounts = async () => {
            try {
                if (!companySelected.value || !companySelected.value.id) {
                    console.error("ID de l'entreprise non trouvé dans companySelected:", companySelected.value);
                    return;
                }

                const response = await axios.get(`${process.env.VUE_APP_BASE_URL}/pcg-special/${companySelected.value.id}`);
                const specialAccountsData = response.data;
                console.log("Comptes spéciaux récupérés:", specialAccountsData);

                // Ajout des comptes spéciaux aux comptes existants
                accounts.value = [
                    ...accounts.value,
                    ...specialAccountsData.map(account => ({
                        label: `${account.accounting_account} - ${account.label}`,
                        key: account.accounting_account,
                        value: account.label
                    }))
                ];
            } catch (error) {
                console.error('Erreur lors de la récupération des comptes spéciaux:', error);
            }
        };

        // Appel de la fonction fetchSpecialAccounts après fetchAccounts
        const fetchAllAccounts = async () => {
            await fetchAccounts();
            await fetchSpecialAccounts();
        };

        // Remplacer l'appel à fetchAccounts par fetchAllAccounts dans le watch
        watch(companySelected, async (newVal) => {
            if (newVal && newVal.evoliz_id) {
                console.log("L'entreprise sélectionnée a été mise à jour:", newVal);
                await fetchAllAccounts();
            }
        }, { immediate: true });


        const filterAccounts = (val, update) => {
            update(() => {
                search.value = val;
            });
        };

        const changeIsTemp = (temp) => {
            console.log(temp)
            isTemp.value = temp
        }

        //Gestion des enregistrements
        const getRegistrationValues = async () => {
            registrationValue.value['total_ttc'] = registration(invoiceDatas.value.total_ttc, invoiceDatas.value.stakeholder_account, invoiceDatas)

        }

        const addRegistrationToProducts = () => {
            products.value.forEach(product => {
                if (stakeholderInfos.value.tva_regime == 'franchise') {
                    product.registration = registration(product.total_amount_ttc, product.account, invoiceDatas)
                } else {
                    product.registration = registration(product.total_amount_ht, product.account, invoiceDatas)
                }
            });
        }

        //Gestion de la TVA
        const tvaAccountsRaw = ref([]);

        const tvaAccounts = computed({
            get: () => tvaAccountsRaw.value,
            set: (newVal) => {
                tvaAccountsRaw.value = newVal;
            }
        });

        const updateTvaAccounts = async () => {
            const computedTvaAccounts = await getTvaAccount(country.value, invoiceDatas.value, products.value);
            tvaAccounts.value = computedTvaAccounts.map(account => ({
                ...account,
                editable: true // Ajout d'une propriété pour gérer l'édition
            }));
        };

        watch([country, invoiceDatas, products], updateTvaAccounts, { immediate: true });
        //Gestion des vérifications
        const verifyEntireInvoice = () => {
            let globalTotalDebit = 0;
            let globalTotalCredit = 0;

            accountingEntriesList.value.forEach(entry => {
                globalTotalDebit += parseFloat(entry.montantDebit);
                globalTotalCredit += parseFloat(entry.montantCredit);
            });

            const totalInvoiceAmount = invoiceDatas.value.total_ttc.toFixed(2);
            const isBalanced = Math.abs(globalTotalDebit.toFixed(2) - globalTotalCredit.toFixed(2)) < Number.EPSILON;

            return {
                isBalanced,
                totalDebit: globalTotalDebit.toFixed(2),
                totalCredit: globalTotalCredit.toFixed(2),
                invoiceTotal: totalInvoiceAmount,
                discrepancy: isBalanced ? 0 : Math.abs(globalTotalDebit - globalTotalCredit).toFixed(2)
            };
        };

        const verifyAccountingEntries = (product) => {
            if (!product) {
                console.error('Product is undefined');
                return false;
            }

            const totalAmountHT = Number(product.total_amount_ht) || 0;
            const tvaRate = Number(product.tva_rate) || 0;
            const totalTTC = Number(product.total_amount_ttc) || 0;

            const tvaAmount = (totalAmountHT * (tvaRate / 100));

            if (totalTTC.toFixed(2) == (totalAmountHT + tvaAmount).toFixed(2)) {
                return true;
            } else {
                return false;
            }
        };

        //Gestion des écritures
        const accountingEntriesList = ref([]);
        const accountingEntriesComputed = computedAsync(async () => {
            try {
                console.log('accountingEntriesComputed is running');

                if (!stakeholderInfos.value) {
                    console.warn('Missing stakeholder information');
                    return [];
                }

                if (!invoiceDatas.value) {
                    console.warn('Missing invoice data');
                    return [];
                }

                console.log('document_type:', invoiceDatas.value.document_type);

                if (!invoiceDatas.value.document_type) {
                    console.warn('Le type de document est manquant ou vide');
                }
            } catch (error) {
                console.log(error)
            }
            var accountingEntries = [];
            let date = new Date();
            const formattedDate = `${date.getFullYear()}${("0" + (date.getMonth() + 1)).slice(-2)}${("0" + date.getDate()).slice(-2)}`;
            try {

                if (stakeholderInfos.value && companySelected.value.tva_regime !== "franchise") {
                    if (invoiceDatas.value.document_type === "facture") {
                        products.value.map(product => {
                            // Écriture pour le débit du compte de charge (compte 6...)
                            product.accountBis = replaceLastTwoCharsWithZero(product.account);
                            var accountName = accounts.value.find(account => account.key === product.accountBis);
                            if (product.registration == "débit") {
                                accountingEntries.push(createFecEntry("AC", "Journal d'achat", lastHA.value, formattedDate, product.account, accountName.value, "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, product.designation, Math.abs(product.total_amount_ht), 0, "", "", "", product.data && product.data.oldCurrencyData ? product.data.oldCurrencyData.original_amount_ht : invoiceDatas.value.data.oldCurrencyData.original_amount, product.data && product.data.oldCurrencyData ? product.data.oldCurrencyData.original_currency : invoiceDatas.value.data.oldCurrencyData.original_currency));
                            } else {
                                accountingEntries.push(createFecEntry("AC", "Journal d'achat", lastHA.value, formattedDate, product.account, accountName.value, "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, product.designation, 0, Math.abs(product.total_amount_ht), "", "", "", product.data && product.data.oldCurrencyData ? product.data.oldCurrencyData.original_amount_ht : invoiceDatas.value.data.oldCurrencyData.original_amount, product.data && product.data.oldCurrencyData ? product.data.oldCurrencyData.original_currency : invoiceDatas.value.data.oldCurrencyData.original_currency));
                            }
                        })

                        extraFees.value.map(product => {
                            // Écriture pour le débit du compte de charge (compte 6...)
                            product.accountBis = replaceLastTwoCharsWithZero(product.account);
                            if (product.registration == "débit") {
                                accountingEntries.push(createFecEntry("AC", "Journal d'achat", lastHA.value, formattedDate, product.account, accountName.value, "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, product.designation, Math.abs(product.total_amount_ht), 0, "", "", "", product.data ? product.data.oldCurrencyData.original_amount_ttc : invoiceDatas.value.data.oldCurrencyData.original_amount, product.data ? product.data.oldCurrencyData.original_currency : invoiceDatas.value.data.oldCurrencyData.original_currency));
                            } else {
                                accountingEntries.push(createFecEntry("AC", "Journal d'achat", lastHA.value, formattedDate, product.account, accountName.value, "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, product.designation, 0, Math.abs(product.total_amount_ht), "", "", "", product.data ? product.data.oldCurrencyData.original_amount_ttc : invoiceDatas.value.data.oldCurrencyData.original_amount, product.data ? product.data.oldCurrencyData.original_currency : invoiceDatas.value.data.oldCurrencyData.original_currency));
                            }
                        })

                        var tvaAccountAmounts = getAllTvaAccountsAndAmounts(tvaAccounts);
                        tvaAccountAmounts.map(tvaAccountAmount => {
                            if (tvaAccountAmount.tvaAmount != 0) {
                                // Écriture pour le débit du compte de TVA
                                accountingEntries.push(createFecEntry("AC", "Journal d'achat", lastHA.value, formattedDate, tvaAccountAmount.account_1, tvaAccountsData[0][tvaAccountAmount.account_1], "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, tvaAccountsData[0][tvaAccountAmount.account_1], Math.abs(tvaAccountAmount.tvaAmount), 0, "", "", "", tvaAccountAmount.tvaAmount, invoiceDatas.value.data.oldCurrencyData.original_currency))
                                if (tvaAccountAmount.account_2) {
                                    accountingEntries.push(createFecEntry("AC", "Journal d'achat", lastHA.value, formattedDate, tvaAccountAmount.account_2, tvaAccountsData[0][tvaAccountAmount.account_2], "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, tvaAccountsData[0][tvaAccountAmount.account_2], 0, Math.abs(tvaAccountAmount.tvaAmount), "", "", "", tvaAccountAmount.tvaAmount, invoiceDatas.value.data.oldCurrencyData.original_currency))
                                }
                            }
                        })
                        // Écriture pour le crédit du compte fournisseur            
                        accountingEntries.push(createFecEntry("AC", "Journal d'achat", lastHA.value, formattedDate, invoiceDatas.value.stakeholder_account, stakeholderInfos.value.name, "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, stakeholderInfos.value.name, 0, invoiceDatas.value.total_ttc, "", "", "", invoiceDatas.value.data.oldCurrencyData.original_amount, invoiceDatas.value.data.oldCurrencyData.original_currency));
                    } else if (invoiceDatas.value.document_type === "avoir") {
                        // Traiter les autres types de documents si nécessaire
                        products.value.map(product => {
                            var accountName = accounts.value.find(account => account.key === product.accountBis);

                            // Écriture pour le débit du compte de charge (compte 6...)
                            product.accountBis = replaceLastTwoCharsWithZero(product.account);
                            if (product.registration == "débit") {
                                accountingEntries.push(createFecEntry("AC", "Journal d'achat", lastHA.value, formattedDate, product.account, accountName.value, "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, product.designation, Math.abs(product.total_amount_ht), 0, "", "", "", product.data && product.data.oldCurrencyData ? product.data.oldCurrencyData.original_amount_ht : invoiceDatas.value.data.oldCurrencyData.original_amount, product.data && product.data.oldCurrencyData ? product.data.oldCurrencyData.original_currency : invoiceDatas.value.data.oldCurrencyData.original_currency));
                            } else {
                                accountingEntries.push(createFecEntry("AC", "Journal d'achat", lastHA.value, formattedDate, product.account, accountName.value, "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, product.designation, 0, Math.abs(product.total_amount_ht), "", "", "", product.data && product.data.oldCurrencyData ? product.data.oldCurrencyData.original_amount_ht : invoiceDatas.value.data.oldCurrencyData.original_amount, product.data && product.data.oldCurrencyData ? product.data.oldCurrencyData.original_currency : invoiceDatas.value.data.oldCurrencyData.original_currency));
                            }
                        })

                        extraFees.value.map(product => {
                            // Écriture pour le débit du compte de charge (compte 6...)
                            product.accountBis = replaceLastTwoCharsWithZero(product.account);
                            if (product.registration == "débit") {
                                accountingEntries.push(createFecEntry("AC", "Journal d'achat", lastHA.value, formattedDate, product.account, accountName.value, "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, product.designation, Math.abs(product.total_amount_ht), 0, "", "", "", product.data ? product.data.oldCurrencyData.original_amount_ttc : invoiceDatas.value.data.oldCurrencyData.original_amount, product.data ? product.data.oldCurrencyData.original_currency : invoiceDatas.value.data.oldCurrencyData.original_currency));
                            } else {
                                accountingEntries.push(createFecEntry("AC", "Journal d'achat", lastHA.value, formattedDate, product.account, accountName.value, "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, product.designation, 0, Math.abs(product.total_amount_ht), "", "", "", product.data ? product.data.oldCurrencyData.original_amount_ttc : invoiceDatas.value.data.oldCurrencyData.original_amount, product.data ? product.data.oldCurrencyData.original_currency : invoiceDatas.value.data.oldCurrencyData.original_currency));
                            }
                        })

                        var tvaAccountAmounts = getAllTvaAccountsAndAmounts(tvaAccounts);
                        tvaAccountAmounts.map(tvaAccountAmount => {
                            if (tvaAccountAmount.tvaAmount != 0) {
                                if (tvaAccountAmount.registration === "débit") {
                                    // Écriture pour le débit du compte de TVA
                                    accountingEntries.push(createFecEntry("AC", "Journal d'achat", lastHA.value, formattedDate, tvaAccountAmount.account_1, tvaAccountsData[0][tvaAccountAmount.account_1], "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, tvaAccountsData[0][tvaAccountAmount.account_1], Math.abs(tvaAccountAmount.tvaAmount), 0, "", "", "", tvaAccountAmount.tvaAmount, invoiceDatas.value.data.oldCurrencyData.original_currency))
                                    if (tvaAccountAmount.account_2) {
                                        accountingEntries.push(createFecEntry("AC", "Journal d'achat", lastHA.value, formattedDate, tvaAccountAmount.account_2, tvaAccountsData[0][tvaAccountAmount.account_2], "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, tvaAccountsData[0][tvaAccountAmount.account_2], 0, Math.abs(tvaAccountAmount.tvaAmount), "", "", "", tvaAccountAmount.tvaAmount, invoiceDatas.value.data.oldCurrencyData.original_currency))
                                    }
                                } else {
                                    // Écriture pour le crédit du compte de TVA
                                    accountingEntries.push(createFecEntry("AC", "Journal d'achat", lastHA.value, formattedDate, tvaAccountAmount.account_1, tvaAccountsData[0][tvaAccountAmount.account_1], "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, tvaAccountsData[0][tvaAccountAmount.account_1], 0, Math.abs(tvaAccountAmount.tvaAmount), "", "", "", tvaAccountAmount.tvaAmount, invoiceDatas.value.data.oldCurrencyData.original_currency))
                                    if (tvaAccountAmount.account_2) {
                                        accountingEntries.push(createFecEntry("AC", "Journal d'achat", lastHA.value, formattedDate, tvaAccountAmount.account_2, tvaAccountsData[0][tvaAccountAmount.account_2], "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, tvaAccountsData[0][tvaAccountAmount.account_2], Math.abs(tvaAccountAmount.tvaAmount), 0, "", "", "", tvaAccountAmount.tvaAmount, invoiceDatas.value.data.oldCurrencyData.original_currency))
                                    }
                                }
                            }
                        })
                        // Écriture pour le crédit du compte fournisseur            
                        accountingEntries.push(createFecEntry("AC", "Journal d'achat", lastHA.value, formattedDate, invoiceDatas.value.stakeholder_account, stakeholderInfos.value.name, "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, stakeholderInfos.value.name, invoiceDatas.value.total_ttc, 0, "", "", "", invoiceDatas.value.total_ttc, invoiceDatas.value.data.oldCurrencyData.original_currency));

                    } else if (invoiceDatas.value.document_type === "acompte") {
                        console.log("c'est un acompte")
                        //Si c'est une facture finale
                        let isDepositFinal = await getIsDepositFinal();
                        if (isDepositFinal) {
                            console.log("L'acompte est final")
                            //Ecriture au debit des produits
                            products.value.map(product => {
                                // Écriture pour le débit du compte de charge (compte 6...)
                                product.accountBis = replaceLastTwoCharsWithZero(product.account);
                                if (product.registration == "débit") {
                                    accountingEntries.push(createFecEntry("AC", "Journal d'achat", lastHA.value, formattedDate, product.account, accountName.value, "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, product.designation, Math.abs(product.total_amount_ht), 0, "", "", "", product.data && product.data.oldCurrencyData ? product.data.oldCurrencyData.original_amount_ht : invoiceDatas.value.data.oldCurrencyData.original_amount, product.data ? product.data.oldCurrencyData.original_currency : invoiceDatas.value.data.oldCurrencyData.original_currency));
                                } else {
                                    accountingEntries.push(createFecEntry("AC", "Journal d'achat", lastHA.value, formattedDate, product.account, accountName.value, "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, product.designation, 0, Math.abs(product.total_amount_ht), "", "", "", product.data && product.data.oldCurrencyData ? product.data.oldCurrencyData.original_amount_ht : invoiceDatas.value.data.oldCurrencyData.original_amount, product.data ? product.data.oldCurrencyData.original_currency : invoiceDatas.value.data.oldCurrencyData.original_currency));
                                }
                            })
                            extraFees.value.map(product => {
                                // Écriture pour le débit du compte de charge (compte 6...)
                                product.accountBis = replaceLastTwoCharsWithZero(product.account);
                                if (product.registration == "débit") {
                                    accountingEntries.push(createFecEntry("AC", "Journal d'achat", lastHA.value, formattedDate, product.account, accountName.value, "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, product.designation, Math.abs(product.total_amount_ht), 0, "", "", "", product.data ? product.data.oldCurrencyData.original_amount_ttc : invoiceDatas.value.data.oldCurrencyData.original_amount, product.data ? product.data.oldCurrencyData.original_currency : invoiceDatas.value.data.oldCurrencyData.original_currency));
                                } else {
                                    accountingEntries.push(createFecEntry("AC", "Journal d'achat", lastHA.value, formattedDate, product.account, accountName.value, "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, product.designation, 0, Math.abs(product.total_amount_ht), "", "", "", product.data ? product.data.oldCurrencyData.original_amount_ttc : invoiceDatas.value.data.oldCurrencyData.original_amount, product.data ? product.data.oldCurrencyData.original_currency : invoiceDatas.value.data.oldCurrencyData.original_currency));
                                }
                            })
                            //Ecriture au débit de la tva de la facture
                            var tvaAccountAmounts = getAllTvaAccountsAndAmounts(tvaAccounts);
                            tvaAccountAmounts.map(tvaAccountAmount => {
                                if (tvaAccountAmount.tvaAmount != 0) {
                                    // Écriture pour le débit du compte de TVA
                                    accountingEntries.push(createFecEntry("AC", "Journal d'achat", lastHA.value, formattedDate, tvaAccountAmount.account_1, tvaAccountsData[0][tvaAccountAmount.account_1], "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, tvaAccountsData[0][tvaAccountAmount.account_1], 0, Math.abs(tvaAccountAmount.tvaAmount), "", "", "", tvaAccountAmount.tvaAmount, invoiceDatas.value.data.oldCurrencyData.original_currency))
                                    if (tvaAccountAmount.account_2) {
                                        accountingEntries.push(createFecEntry("AC", "Journal d'achat", lastHA.value, formattedDate, tvaAccountAmount.account_2, tvaAccountsData[0][tvaAccountAmount.account_2], "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, tvaAccountsData[0][tvaAccountAmount.account_2], Math.abs(tvaAccountAmount.tvaAmount), 0, "", "", "", tvaAccountAmount.tvaAmount, invoiceDatas.value.data.oldCurrencyData.original_currency))
                                    }
                                }
                            })
                            //Ecriture au credit du compte de décaissement
                            accountingEntries.push(createFecEntry("AC", "Journal d'achat", lastHA.value, invoiceDatas.value, "Fournisseurs débiteurs - Avances et acomptes versés sur commandes", '4091000000', 0, invoiceDatas.value.depositsAmount, stakeholderInfos.value.name + " - " + invoiceDatas.value.invoice_number, invoiceDatas.value.data.oldCurrencyData.original_currency, invoiceDatas.value.data.oldCurrencyData.original_amount));

                            //Ecriture au crédit du compte fournisseur
                            accountingEntries.push(createFecEntry("AC", "Journal d'achat", lastHA.value, formattedDate, invoiceDatas.value.stakeholder_account, stakeholderInfos.value.name, "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, stakeholderInfos.value.name, invoiceDatas.value.total_ttc, 0, "", "", "", invoiceDatas.value.total_ttc, invoiceDatas.value.data.oldCurrencyData.original_currency));

                            //Ecriture de la tva
                        }
                        //Sinon c'est une facture normale
                        else {
                            console.log("L'acompte n'est pas final")
                            var tvaAccountAmounts = getAllTvaAccountsAndAmounts(tvaAccounts);

                            products.value.map(product => {
                                //Détermination quel tva est utilisé
                                var tvaEnd;
                                tvaAccountAmounts.map(tvaAccount => {
                                    console.log(tvaAccount)
                                    if (tvaAccount.tvaRate == "20%") tvaEnd = "20";
                                    if (tvaAccount.tvaRate == "13%") tvaEnd = "13";
                                    if (tvaAccount.tvaRate == "10%") tvaEnd = "10";
                                    if (tvaAccount.tvaRate == "5.5%") tvaEnd = "55";
                                    if (tvaAccount.tvaRate == "2.1%") tvaEnd = "21";
                                    if (tvaAccount.tvaRate == "0%") tvaEnd = "00";
                                })
                                // Écriture pour le débit du compte d'attente
                                product.accountBis = replaceLastTwoCharsWithZero(product.account);
                                if (product.registration == "débit") {
                                    accountingEntries.push(createFecEntry("AC", "Journal d'achat", lastHA.value, formattedDate, "40910000" + tvaEnd, "Fournisseurs débiteurs - Avances et acomptes versés sur commandes", "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, invoiceDatas.value.supplier_name + " - " + invoiceDatas.value.invoice_number, Math.abs(product.total_amount_ht), 0, "", "", "", product.data && product.data.oldCurrencyData ? product.data.oldCurrencyData.original_amount_ht : invoiceDatas.value.data.oldCurrencyData.original_amount, product.data && product.data.oldCurrencyData ? product.data.oldCurrencyData.original_currency : invoiceDatas.value.data.oldCurrencyData.original_currency));
                                } else {
                                    accountingEntries.push(createFecEntry("AC", "Journal d'achat", lastHA.value, formattedDate, "40910000" + tvaEnd, "Fournisseurs débiteurs - Avances et acomptes versés sur commandes", "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, invoiceDatas.value.supplier_name + " - " + invoiceDatas.value.invoice_number, 0, Math.abs(product.total_amount_ht), "", "", "", product.data && product.data.oldCurrencyData ? product.data.oldCurrencyData.original_amount_ht : invoiceDatas.value.data.oldCurrencyData.original_amount, product.data && product.data.oldCurrencyData ? product.data.oldCurrencyData.original_currency : invoiceDatas.value.data.oldCurrencyData.original_currency));
                                }
                            })

                            //Ecriture au débit du compte de TVA
                            tvaAccountAmounts.map(tvaAccountAmount => {
                                if (tvaAccountAmount.tvaAmount != 0) {
                                    // Écriture pour le débit du compte de TVA
                                    accountingEntries.push(createFecEntry("AC", "Journal d'achat", lastHA.value, formattedDate, tvaAccountAmount.account_1, tvaAccountsData[0][tvaAccountAmount.account_1], "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, tvaAccountsData[0][tvaAccountAmount.account_1], Math.abs(tvaAccountAmount.tvaAmount), 0, "", "", "", tvaAccountAmount.tvaAmount, invoiceDatas.value.data.oldCurrencyData.original_currency))
                                    if (tvaAccountAmount.account_2) {
                                        accountingEntries.push(createFecEntry("AC", "Journal d'achat", lastHA.value, formattedDate, tvaAccountAmount.account_2, tvaAccountsData[0][tvaAccountAmount.account_2], "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, tvaAccountsData[0][tvaAccountAmount.account_2], 0, Math.abs(tvaAccountAmount.tvaAmount), "", "", "", tvaAccountAmount.tvaAmount, invoiceDatas.value.data.oldCurrencyData.original_currency))
                                    }
                                }
                            })
                            // Écriture pour le crédit du compte fournisseur            
                            accountingEntries.push(createFecEntry("AC", "Journal d'achat", lastHA.value, formattedDate, invoiceDatas.value.stakeholder_account, stakeholderInfos.value.name, "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, stakeholderInfos.value.name, 0, invoiceDatas.value.total_ttc, "", "", "", invoiceDatas.value.total_ttc, invoiceDatas.value.data.oldCurrencyData.original_currency));

                        }
                    }
                } else {
                    // Traitement spécifique pour les entreprises en franchise de TVA
                    if (invoiceDatas.value.document_type === "facture") {
                        products.value.forEach(product => {

                            // Écriture pour le débit du compte de charge (compte 6...) sans TVA
                            var accountName = accounts.value.find(account => account.key === product.accountBis);
                            product.accountBis = replaceLastTwoCharsWithZero(product.account);
                            accountingEntries.push(createFecEntry("AC", "Journal d'achat", lastHA.value, formattedDate, product.account, accountName.value, "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, product.designation, product.total_amount_ttc, 0, "", "", "", product.data ? product.data.oldCurrencyData.original_amount_ttc : invoiceDatas.value.data.oldCurrencyData.original_amount, product.data ? product.data.oldCurrencyData.original_currency : invoiceDatas.value.data.oldCurrencyData.original_currency));
                        });

                        extraFees.value.forEach(product => {
                            // Écriture pour le débit du compte de charge (compte 6...) pour les frais supplémentaires sans TVA
                            product.accountBis = replaceLastTwoCharsWithZero(product.account);
                            accountingEntries.push(createFecEntry("AC", "Journal d'achat", lastHA.value, formattedDate, product.account, accountName.value, "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, product.designation, product.total_amount_ttc, 0, "", "", "", product.data ? product.data.oldCurrencyData.original_amount_ttc : invoiceDatas.value.data.oldCurrencyData.original_amount, product.data ? product.data.oldCurrencyData.original_currency : invoiceDatas.value.data.oldCurrencyData.original_currency));
                        });

                        // Écriture pour le crédit du compte fournisseur sans TVA
                        accountingEntries.push(createFecEntry("AC", "Journal d'achat", lastHA.value, formattedDate, invoiceDatas.value.stakeholder_account, stakeholderInfos.value.name, "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, stakeholderInfos.value.name, 0, invoiceDatas.value.total_ttc, "", "", "", invoiceDatas.value.total_amount_ttc, invoiceDatas.value.data.oldCurrencyData.original_currency));

                    } else if (invoiceDatas.value.document_type === "avoir") {
                        // Gestion des avoirs en franchise de TVA
                        products.value.forEach(product => {
                            // Écriture pour le crédit du compte de charge (compte 6...) sans TVA
                            product.accountBis = replaceLastTwoCharsWithZero(product.account);
                            accountingEntries.push(createFecEntry("AC", "AVOIR", "", formattedDate, product.account, accountName.value, "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, product.designation, 0, product.total_amount_ttc, "", "", "", product.data ? product.data.oldCurrencyData.original_amount_ttc : invoiceDatas.value.data.oldCurrencyData.original_amount, product.data ? product.data.oldCurrencyData.original_currency : invoiceDatas.value.data.oldCurrencyData.original_currency));
                        });

                        extraFees.value.forEach(product => {
                            // Écriture pour le crédit du compte de charge (compte 6...) pour les frais supplémentaires sans TVA
                            product.accountBis = replaceLastTwoCharsWithZero(product.account);
                            accountingEntries.push(createFecEntry("AC", "AVOIR", "", formattedDate, product.account, accountName.value, "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, product.designation, 0, product.total_amount_ttc, "", "", "", product.data ? product.data.oldCurrencyData.original_amount_ttc : invoiceDatas.value.data.oldCurrencyData.original_amount, product.data ? product.data.oldCurrencyData.original_currency : invoiceDatas.value.data.oldCurrencyData.original_currency));
                        });

                        // Écriture pour le débit du compte fournisseur sans TVA
                        accountingEntries.push(createFecEntry("AC", "AVOIR", "", formattedDate, invoiceDatas.value.stakeholder_account, stakeholderInfos.value.name, "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, stakeholderInfos.value.name, invoiceDatas.value.total_ttc, 0, "", "", "", invoiceDatas.value.total_amount_ttc, invoiceDatas.value.data.oldCurrencyData.original_currency));
                    }
                }

                accountingEntriesList.value = accountingEntries
                return accountingEntries
            } catch (error) {
                console.log("Erreur lors de la génération des écritures comptables:", error);
            }
        },
        );

        //Gestion des immo
        const isOneImmo = ref([]);
        const handleOneImmo = async (product, immo) => {
            try {
                // Ajout de comptes
                accounts.value = accounts.value.concat(
                    Object.entries(accountsData2[0]).map(([key, value]) => ({ label: `${key} - ${value}`, key: key, value: value }))
                );
                console.log("Comptes ajoutés:", accounts.value);

                // Récupérer le montant HT et le taux de TVA du produit
                let totalAmountHT = parseFloat(product.total_amount_ht.toFixed(2));
                let tvaRate = parseFloat(product.tva_rate.toFixed(2));

                console.log("Montant HT:", totalAmountHT);
                console.log("Taux de TVA:", tvaRate);

                if (isNaN(totalAmountHT) || isNaN(tvaRate)) {
                    throw new Error("Invalid total_amount_ht or tva_rate");
                }

                // Calculer le montant de TVA
                let tvaAmount = parseFloat(((totalAmountHT * tvaRate) / 100).toFixed(2));
                console.log("Montant de TVA calculé:", tvaAmount);

                if (immo) {
                    // Cas immobilisation : ajouter le montant de TVA au compte de TVA immobilisation
                    let tvaImmoAccount = tvaAccounts.value.find(account => account[`tva_immo_${tvaRate}`] !== undefined);
                    if (tvaImmoAccount) {
                        tvaImmoAccount[`tva_immo_${tvaRate}`] = parseFloat((tvaImmoAccount[`tva_immo_${tvaRate}`] + tvaAmount).toFixed(2));
                        console.log(`TVA sur immobilisation mise à jour pour le taux ${tvaRate}:`, tvaImmoAccount);
                    } else {
                        tvaAccounts.value.push({
                            [`tva_immo_${tvaRate}`]: tvaAmount,
                            account1: `44562100${tvaRate}`,
                            editable: true
                        });
                        console.log(`TVA sur immobilisation ajoutée pour le taux ${tvaRate}:`, tvaAmount);
                    }
                    let tvaDeductibleAccount = tvaAccounts.value.find(account => account[`tva_${tvaRate}`] !== undefined);
                    if (tvaDeductibleAccount) {
                        tvaDeductibleAccount[`tva_${tvaRate}`] = parseFloat((tvaDeductibleAccount[`tva_${tvaRate}`] - tvaAmount).toFixed(2));
                        console.log(`TVA déductible mise à jour pour le taux ${tvaRate}:`, tvaDeductibleAccount);
                    }
                } else {
                    // Cas non immobilisation : soustraire le montant de TVA du compte de TVA immobilisation
                    let tvaImmoAccount = tvaAccounts.value.find(account => account[`tva_immo_${tvaRate}`] !== undefined);
                    if (tvaImmoAccount) {
                        tvaImmoAccount[`tva_immo_${tvaRate}`] = parseFloat((tvaImmoAccount[`tva_immo_${tvaRate}`] - tvaAmount).toFixed(2));
                        console.log(`TVA sur immobilisation soustraite pour le taux ${tvaRate}:`, tvaImmoAccount);
                        // Si le montant devient zéro ou négatif, supprimer l'entrée
                        if (tvaImmoAccount[`tva_immo_${tvaRate}`] <= 0) {
                            tvaAccounts.value = tvaAccounts.value.filter(account => account !== tvaImmoAccount);
                            console.log(`TVA sur immobilisation supprimée pour le taux ${tvaRate}:`, tvaImmoAccount);
                        }
                    }
                    // Ajouter le montant à la TVA déductible normale
                    let tvaDeductibleAccount = tvaAccounts.value.find(account => account[`tva_${tvaRate}`] !== undefined);
                    if (tvaDeductibleAccount) {
                        tvaDeductibleAccount[`tva_${tvaRate}`] = parseFloat((tvaDeductibleAccount[`tva_${tvaRate}`] + tvaAmount).toFixed(2));
                        console.log(`TVA déductible ajoutée pour le taux ${tvaRate}:`, tvaDeductibleAccount);
                    }
                }

                console.log("État final des comptes TVA:", tvaAccounts.value);

            } catch (error) {
                console.log("Erreur dans handleOneImmo:", error);
            }
        };


        //Gestion des extraFees
        const extraFees = ref([]);
        const otherTable = ref();
        const getExtraFees2 = async () => {
            try {
                extraFees.value = [];
                // Récupération de l'id de la facture
                const invoiceId = invoiceDatas.value.id;
                // Récupération du texte océrisé
                let url = `${process.env.VUE_APP_BASE_URL}/otherTable/${invoiceId}`;
                let response = await axios.get(url);
                console.log("tables de la facture", response.data)
                otherTable.value = response.data[0].other_table;

                // Conversion du texte de la facture en minuscule pour la comparaison
                const fullTextLowerCase = otherTable.value.fullText.toLowerCase();
                // Recherche de la marketplace dans le texte de la facture
                //const marketplaceFound = marketplaces.find(marketplace => fullTextLowerCase.includes(marketplace.toLowerCase()));

                // Envoi de l'ocr à l'IA
                let url2 = `${process.env.VUE_APP_BASE_URL}/extractInvoiceDetails`;
                let postBody = {
                    'otherTable': otherTable.value
                };
                let response2 = await axios.post(url2, postBody);
                let fees = response2.data;
                console.log(fees)
                // Vérification des frais supplémentaires
                if (fees.extraFees.billingFees !== null && fees.extraFees.billingFees !== 'null') {
                    extraFees.value = [...extraFees.value, ...fees.extraFees.billingFees]
                }
                if (fees.extraFees.shippingFees != null && fees.extraFees.shippingFees !== 'null') {
                    extraFees.value = [...extraFees.value, ...fees.extraFees.shippingFees]
                }
                if (fees.extraFees.depositFees != null && fees.extraFees.depositFees != 'null') {
                    extraFees.value = [...extraFees.value, ...fees.extraFees.depositFees]
                }
                // Assignation du mode de paiement et du type de fournisseur
                invoicePaymentMethod.value = fees.paymentMethod;
                console.log(fees)
                var marketplaceFound;
                if (Array.isArray(fees.marketplace)) {
                    marketplaceFound = fees.marketplace[0];
                } else if (typeof fees.marketplace === 'object' && fees.marketplace !== null) {
                    marketplaceFound = fees.marketplace;
                }

                if (marketplaceFound.isMarketPlace) {
                    supplierType.value = "MarketPlace";
                    marketPlaceName.value = marketplaceFound.marketPlaceName;
                } else {
                    supplierType.value = "Classique";
                    marketPlaceName.value = null;
                }
                const filteredExtraFees = extraFees.value.filter(extraFee => extraFee.total_amount_ht !== 0);

                filteredExtraFees.forEach(extraFee => {
                    extraFee.registration = registration(extraFee.total_amount_ht, extraFee.account);
                });

                extraFees.value = filteredExtraFees;
                updateExtraFeesAccount();

            } catch (error) {
                console.error('Error in getExtraFees2:', error);
            }
        }

        const updateExtraFeesAccount = () => {
            extraFees.value.forEach(fee => {
                if (!fee.account) {
                    return;
                }

                let newAccountSuffix;
                if (fee.tva_rate === 20) {
                    newAccountSuffix = '20';
                } else if (fee.tva_rate === 13) {
                    newAccountSuffix = '13';
                } else if (fee.tva_rate === 10) {
                    newAccountSuffix = '10';
                } else if (fee.tva_rate === 5.5) {
                    newAccountSuffix = '55';
                } else if (fee.tva_rate === 2.1) {
                    newAccountSuffix = '21';
                } else if (fee.tva_rate === 0) {
                    newAccountSuffix = '00';
                }

                if (newAccountSuffix) {
                    fee.account = fee.account.slice(0, -2) + newAccountSuffix;
                }
            })
        }

        const addExtraFees = () => {
            extraFees.value.push({
                designation: "",
                pack_unity: "",
                pack_number: 0,
                stock_name: "",
                quantity: 1,
                tva_rate: 20,
                account: "60110000020",
                accountBis: "60110000000",
                total_amount_ht: 0,
                total_amount_ttc: 0,
                registration: ""
            })
        }

        const removeExtraFees = (extraFee) => {
            var indexToDelete = -1;
            for (var i = 0; i < extraFees.value.length; i++) {
                if (extraFees.value[i].designation === extraFee.designation) {
                    indexToDelete = i;
                    break;
                }
            }
            if (indexToDelete !== -1) {
                extraFees.value.splice(indexToDelete, 1);
            }
        }

        //Validation des données
        const approveData = async () => {
            try {
                //Si le document est une facture définitive
                if (!isTemp.value) {
                    updateData();
                    return;
                }
                //Création du FEC
                var entries = accountingEntriesList.value;
                entries = entries.flat();
                let fec = entries.map(formatFecEntry).join('\n');
                //let fec2 = decaissementEntries.value.map(formatFecEntry).join('\n');
                //fec = fec + '\n' + fec2;
                //Ajout des données supplémentaire de la facture
                invoiceDatas.value.supplier_type = supplierType.value;
                invoiceDatas.value.marketplace_name = marketPlaceName.value;
                invoiceDatas.value.payment_method = invoicePaymentMethod.value;
                //Envoi des données à l'API
                var url = `${process.env.VUE_APP_BASE_URL}/invoiceValidation`;
                var response = await axios.post(url, {
                    invoiceDatas: invoiceDatas.value,
                    productsDatas: products.value,
                    tvaAccountsDatas: tvaAccounts.value,
                    extraFeesDatas: extraFees.value,
                    supplierName: stakeholderInfos.value.name,
                })

                //Envoi du FEC à l'API
                var url2 = `${process.env.VUE_APP_BASE_URL}/linkInvoiceToFec`;
                await axios.post(url2, {
                    fec: fec,
                    invoiceId: response.data.invoiceId
                })
                console.log("last_ha", lastHA.value)
                //Mise à jour du last_ha
                var url3 = `${process.env.VUE_APP_BASE_URL}/accounting_exercice/${companySelected.value.id}/${invoiceDatas.value.invoice_date}`;
                await axios.put(url3, {
                    last_ha: lastHA.value || "HA00000"
                })

                //Envoi de la facture à Zoho
                var zohoBody = {
                    stakeholderId: stakeholderInfos.value.id,
                    companyCode: companySelected.value.company_code,
                    companyId: companySelected.value.id,
                    stakeholderAccount: invoiceDatas.value.stakeholder_account,
                    stakeholderName: stakeholderInfos.value.name,
                    invoiceDate: invoiceDatas.value.invoice_date,
                    invoiceNumber: invoiceDatas.value.invoice_number,
                    fileName: fileName.value,
                    file: await convertBlobToBase64(pdfUrl.value)

                }
                var url3 = `${process.env.VUE_APP_BASE_URL}/invoiceToZoho`;
                await axios.post(url3, zohoBody);
                //Confirmation de la validation
                confirmationMessage.value = `La facture a bien été validée.`;
                messageColor.value = 'green'; // Vert pour le succès
                showMessage.value = true;
                setTimeout(() => {
                    showMessage.value = false;
                }, 5000);

            } catch (error) {
                console.log(error)
                confirmationMessage.value = 'Erreur dans la validation de la facture.';
                messageColor.value = 'red'; // Rouge pour l'erreur
                showMessage.value = true;
                setTimeout(() => {
                    showMessage.value = false;
                }, 5000);

            }
        }

        const sendToEvoliz = async () => {
            try {
                isLoading.value = true;
                // Convertir le PDF en base64
                const pdfBase64 = await convertBlobToBase64(pdfUrl.value);
                var url3 = `${process.env.VUE_APP_BASE_URL}/invoiceToEvoliz`;
                var response3 = await axios.post(url3, {
                    invoiceDatas: invoiceDatas.value,
                    productsDatas: products.value,
                    tvaAccountsDatas: tvaAccounts.value,
                    extraFeesDatas: extraFees.value,
                    supplierName: stakeholderInfos.value.name,
                    companySelected: companySelected.value,
                    pdfUrl: pdfBase64
                })
                //Confirmation de la validation
                confirmationMessage.value = `La facture a bien été envoyée dans Evoliz.`;
                messageColor.value = 'green'; // Vert pour le succès
                showMessage.value = true;
                isLoading.value = false
                setTimeout(() => {
                    showMessage.value = false;
                }, 5000);
            } catch (error) {
                console.log(error);
                confirmationMessage.value = 'Erreur dans l\'envoi dans Evoliz.';
                messageColor.value = 'red'; // Rouge pour l'erreur
                showMessage.value = true;
                isLoading.value = false
                setTimeout(() => {
                    showMessage.value = false;
                }, 5000);
            }
        }

        //Mise a jout des données de la facture dévinitive
        const updateData = async () => {
            try {
                console.log("Début de la mise à jour des données...");

                // Ajout des données supplémentaires de la facture
                console.log("Ajout des données supplémentaires à la facture...");
                invoiceDatas.value.supplier_type = supplierType.value;
                invoiceDatas.value.marketplace_name = marketPlaceName.value;
                invoiceDatas.value.payment_method = invoicePaymentMethod.value;

                // Modification de la facture
                var url = `${process.env.VUE_APP_BASE_URL}/invoice`;
                console.log("Envoi des données de la facture à l'API:", url);
                var response = await axios.put(url, {
                    invoiceDatas: invoiceDatas.value,
                    productsDatas: products.value,
                    tvaAccountsDatas: tvaAccounts.value,
                });
                console.log("Réponse de l'API pour la modification de la facture:", response.data);

                // Génération du FEC
                console.log("Génération des écritures comptables pour le FEC...");
                var entries = accountingEntriesList.value;
                entries = entries.flat();
                let fec = entries.map(formatFecEntry).join('\n');

                // Modification du FEC
                var url2 = `${process.env.VUE_APP_BASE_URL}/linkInvoiceToFec`;
                console.log("Envoi des écritures comptables à l'API:", url2);
                console.log("Envoi du fec:", fec);
                var response2 = await axios.put(url2, {
                    fec: fec,
                    invoiceId: invoiceDatas.value.id
                });
                console.log("Réponse de l'API pour la modification du FEC:", response2.data);

                // Confirmation de la modification
                confirmationMessage.value = `La facture a bien été modifiée.`;
                messageColor.value = 'green'; // Vert pour le succès
                showMessage.value = true;
                setTimeout(() => {
                    showMessage.value = false;
                }, 5000);

            } catch (error) {
                console.log("Erreur lors de la mise à jour des données:", error);
                confirmationMessage.value = 'Erreur dans la modification de la facture.';
                messageColor.value = 'red'; // Rouge pour l'erreur
                showMessage.value = true;
                setTimeout(() => {
                    showMessage.value = false;
                }, 5000);
            }
        }


        async function convertBlobToBase64(blobUrl) {
            const response = await fetch(blobUrl);
            const blob = await response.blob();
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result.split(',')[1]); // Extraire les données base64
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });
        }

        const deleteInvoiceTemp = async () => {
            try {
                const invoiceId = invoiceDatas.value.id;
                console.log(`Suppression de la facture temporaire avec l'ID : ${invoiceId}`);

                isLoading.value = true; // Activer l'indicateur de chargement

                const response = await axios.delete(`${process.env.VUE_APP_BASE_URL}/invoiceTemp/${invoiceId}`);
                console.log("Réponse de l'API pour la suppression de la facture temporaire :", response.data);

                // Mise à jour de l'interface utilisateur
                confirmationMessage.value = 'La facture temporaire a été supprimée avec succès.';
                messageColor.value = 'green';
                showMessage.value = true;

                // Réinitialiser les données de la facture
                invoiceDatas.value = {};
                products.value = [];
                extraFees.value = [];
                tvaAccounts.value = [];
                pdfUrl.value = null;


            } catch (error) {
                console.error("Erreur lors de la suppression de la facture temporaire:", error);

                // Afficher un message d'erreur détaillé
                confirmationMessage.value = `Erreur lors de la suppression de la facture : ${error.response?.data?.message || error.message}`;
                messageColor.value = 'red';
                showMessage.value = true;
            } finally {
                isLoading.value = false; // Désactiver l'indicateur de chargement

                // Cacher le message après un certain délai
                setTimeout(() => {
                    showMessage.value = false;
                }, 5000);
            }
        }

        const confirmDelete = () => {
            if (isTemp.value) {
                if (confirm('Êtes-vous sûr de vouloir supprimer cette facture temporaire ?')) {
                    deleteInvoiceTemp();
                }
            } else {
                if (confirm('Êtes-vous sûr de vouloir supprimer cette facture définitive ?')) {
                    deleteInvoice();
                }
            }
        }

        const deleteInvoice = async () => {
            try {
                const invoiceId = invoiceDatas.value.id;
                console.log(`Suppression de la facture définitive avec l'ID : ${invoiceId}`);

                const response = await axios.delete(`${process.env.VUE_APP_BASE_URL}/invoice/${invoiceId}`);
                console.log("Réponse de l'API pour la suppression de la facture définitive :", response.data);

            } catch (error) {
                console.error("Erreur lors de la suppression de la facture définitive:", error);

                confirmationMessage.value = `Erreur lors de la suppression de la facture : ${error.response?.data?.message || error.message}`;
                messageColor.value = 'red';
                showMessage.value = true;
            }
        }

        const getTvaAccountPlaceholder = () => {
            const activeTvaAccount = tvaAccounts.value.find(account =>
                account.tva_20 !== 0 ||
                account.tva_13 !== 0 ||
                account.tva_10 !== 0 ||
                account.tva_55 !== 0 ||
                account.tva_21 !== 0
            );

            if (activeTvaAccount) {
                return activeTvaAccount.account1;
            } else {
                showTvaWarning.value = true;
                return "Veuillez sélectionner un compte de TVA actif";
            }
        };

        async function downloadFec() {
            try {
                //Récupération des factures validées
                let url = `${process.env.VUE_APP_BASE_URL}/validatedInvoices/${companySelected.value.id}`;
                let response = await axios.get(url);
                let invoices = response.data;
                console.log(invoices);

                //Récupération de la dernière session (session la plus élevée)
                let lastInvoices = invoices.filter(invoice => invoice.session_number === Math.max(...invoices.map(invoice => invoice.session_number)));
                console.log(lastInvoices);

                // Ligne d'en-tête du FEC
                const fecHeader = "JournalCode;JournalLib;EcritureNum;EcritureDate;CompteNum;CompteLib;CompAuxNum;CompAuxLib;PieceRef;PieceDate;EcritureLib;Debit;Credit;EcritureLet;DateLet;ValidDate;Montantdevise;Idevise\n";

                //Pour chaque facture de la dernière session, on récupère les fec
                var fec = fecHeader;
                for (let invoice of lastInvoices) {
                    let url2 = `${process.env.VUE_APP_BASE_URL}/invoiceFec/${invoice.id}`;
                    let response2 = await axios.get(url2);
                    console.log(response2.data);
                    //Ajout au fec avec un retour à la ligne sauf pour la première facture et pour la dernière
                    if (response2.data.length > 0) {
                        fec += response2.data[0].fec + "\n";
                    }
                }

                //Création du fichier à partir du fec
                // Créer un objet Blob avec le contenu du fichier
                let blob = new Blob([fec], { type: 'text/plain' });

                // Créer un lien pour le téléchargement
                let downloadLink = document.createElement("a");
                downloadLink.href = window.URL.createObjectURL(blob);
                //Création du nom en fonction de la date
                let date = new Date();
                let name = `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${("0" + date.getDate()).slice(-2)}`;
                downloadLink.download = `export_${name}.txt`;

                // Simuler un clic sur le lien pour déclencher le téléchargement
                document.body.appendChild(downloadLink);
                downloadLink.click();

                // Nettoyer et supprimer le lien
                document.body.removeChild(downloadLink);

            } catch (error) {
                console.log(error);
            }
        }

        const downloadFec2 = async () => {
            try {
                const companyId = companySelected.value.id;
                const url = `${process.env.VUE_APP_BASE_URL}/fecExport/${companyId}`;

                // Définir les dates de l'exercice comptable
                const body = {
                    accounting_year: {
                        exercice_start: "2024-08-01",
                        exercice_end: "2025-12-31"
                    }
                };

                // Faire l'appel API
                const response = await axios.post(url, body, { responseType: 'blob' });

                // Extraire le nom du fichier de l'en-tête de la réponse
                const contentDisposition = response.headers['content-disposition'];
                let fileName = 'export_FEC.txt'; // Nom par défaut
                if (contentDisposition) {
                    const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/i);
                    if (fileNameMatch.length === 2)
                        fileName = fileNameMatch[1];
                }

                // Créer un objet Blob avec la réponse
                const blob = new Blob([response.data], { type: 'text/plain' });

                // Créer un lien pour le téléchargement
                const downloadLink = document.createElement("a");
                downloadLink.href = URL.createObjectURL(blob);
                downloadLink.download = fileName;

                // Simuler un clic pour déclencher le téléchargement
                document.body.appendChild(downloadLink);
                downloadLink.click();

                // Nettoyer
                document.body.removeChild(downloadLink);
                URL.revokeObjectURL(downloadLink.href);

                console.log("Téléchargement du FEC réussi");
            } catch (error) {
                console.error("Erreur lors du téléchargement du FEC:", error);
            }
        };

        //Gestion de l'animation
        const isScrolledPast = ref(false);
        const initialPosition = ref(null);

        const handleScroll = () => {
            if (!initialPosition.value) {
                const element = document.querySelector('.verificationResult');
                if (element)
                    initialPosition.value = element.getBoundingClientRect().top + window.scrollY;
            }

            const scrollPosition = window.scrollY;
            isScrolledPast.value = scrollPosition > initialPosition.value;
        };

        //Gestion de l'ajout d'un nouveau compte d'achat
        const isAddingNewAccount = ref(false);
        const newPurchaseAccount = ref({
            account: '',
            designation: '',
            description: '',
            tvaAccount: ''
        });
        const toggleNewAccountForm = (product) => {
            isAddingNewAccount.value = !isAddingNewAccount.value;
            if (isAddingNewAccount.value && product) {
                newPurchaseAccount.value.description = product.designation;
            }
            newPurchaseAccount.value.tvaAccount = getTvaAccountPlaceholder();
        };

        const saveNewPurchaseAccount = async () => {
            try {
                isSaving.value = true;
                // Logique pour enregistrer le nouveau compte d'achat
                console.log('Nouveau compte ajouté:', newPurchaseAccount.value);

                //Récupération des services de l'utilisateur
                var companies = store.state.companies;
                if(companies && companies.length > 0) {
                    var company = companies.filter(company => Number(company.taac_company_id) === Number(companySelected.value.id));
                console.log(company);
                }
                if (company[0].services.some(service => service.service_id === "Q6f1PnZ1tx324KeeZAwF") || store.getters.currentUser.role === "super_admin") {
                    console.log("Service Archipilot trouvé");
                    // Envoie de la requête avec le companyId
                    const response = await axios.post(`${process.env.VUE_APP_BASE_URL}/create-account`, {
                        companyId: companySelected.value.id,
                        evolizCompanyId: companySelected.value.evoliz_id,
                        accountData: newPurchaseAccount.value
                    });
                    console.log('Nouveau compte ajouté dans Evoliz et dans pcg_special:', response.data);
                    productMessage.value = 'Le nouveau compte et sa classification ont été ajouté avec succès.';
                    productMessageColor.value = 'green';

                } else {
                    console.log("Pas de service Archipilot");
                    const response = await axios.post(`${process.env.VUE_APP_BASE_URL}/pcg-special`, {
                        accounting_account: newPurchaseAccount.value.account,
                        label: newPurchaseAccount.value.designation,
                        classification_code: '', 
                        description: newPurchaseAccount.value.description,
                        company_id: companySelected.value.id
                    });
                    console.log('Nouveau compte ajouté dans pcg_special:', response.data);
                    productMessage.value = 'Le nouveau compte a été ajouté avec succès.';
                    productMessageColor.value = 'green';
                }
                await fetchAllAccounts();

                // Reset du formulaire
                newPurchaseAccount.value = {
                    account: '',
                    designation: '',
                    description: '',
                    tvaAccount: ''
                };
                isAddingNewAccount.value = false;
                isSaving.value = false;
                setTimeout(() => {
                    productMessage.value = '';
                    productMessageColor.value = '';
                }, 5000);
                
            } catch (error) {
                console.error('Erreur lors de l\'enregistrement du nouveau compte:', error);
                productMessage.value = 'Erreur lors de l\'enregistrement du nouveau compte.';
                productMessageColor.value = 'red';
                setTimeout(() => {
                    productMessage.value = '';
                    productMessageColor.value = '';
                }, 5000);
            }
        };


        //Gestion des acomptes
        const getIsDepositFinal = async () => {
            try {
                var url = `${process.env.VUE_APP_BASE_URL}/isDepositFinalInvoice`;
                var response = await axios.post(url, { invoiceText: otherTable.value.fullText });
                console.log("reponse isDepositFinal")
                console.log(response.data)
                invoiceDatas.value["depositsAmount"] = response.data.depositsAmount
                return response.data.isFinalInvoice
            } catch (error) {
                console.log(error);
            }
        }
        const companyStakeholder = ref([]);
        //Gestion des comptes fournisseurs
        const fetchCompanyStakeholder = async () => {
            try {
                var url = `${process.env.VUE_APP_BASE_URL}/company_stakeholders/${companySelected.value.id}`;
                var response = await axios.get(url);
                console.log("reponse companyStakeholder")
                console.log(response.data)
                companyStakeholder.value = response.data
            } catch (error) {
                console.log(error);
            }
        }

        //Gestion de la conversion HT -> TTC
        // Gestion de la conversion HT -> TTC
        const convertHtToTtc = () => {
            products.value.forEach(product => {
                //Calcul du nouveau HT
                const tvaMultiplier = 1 + product.tva_rate / 100;
                const newHt = (product.total_amount_ht / tvaMultiplier).toFixed(2);
                //On place le HT dans le TTC
                product.total_amount_ttc = product.total_amount_ht
                //On place le nouveau HT dans le produit
                product.total_amount_ht = parseFloat(newHt).toFixed(2);
            });
        };

        // Gestion de la conversion TTC -> HT
        const convertTtcToHt = () => {
            products.value.forEach(product => {
                //Calcul du nouveau HT
                const tvaMultiplier = 1 + product.tva_rate / 100;
                //On place le nouveau HT dans le produit
                product.total_amount_ht = parseFloat(product.total_amount_ttc).toFixed(2);
                //On calcule le nouveau total TTC
                const newTtc = (product.total_amount_ht * tvaMultiplier).toFixed(2);
                product.total_amount_ttc = parseFloat(newTtc).toFixed(2);
            })
        };

        //Gestion des données liées à l'exercice
        const fetchAccountingExerciceData = async () => {
            try {
                var url = `${process.env.VUE_APP_BASE_URL}/accounting_exercice/${companySelected.value.id}/${invoiceDatas.value.invoice_date}`;
                var response = await axios.get(url);
                console.log("reponse accountingExerciceData")
                console.log(response.data)
                var newHA = Number(response.data.last_ha.slice(-6)) + 1;
                lastHA.value = "HA" + newHA.toString().padStart(6, '0');
            } catch (error) {
                console.log(error);
            }
        }

        const updateStakeholderAccount = () => {
            var newStakeholder = companyStakeholder.value.filter(stakeholder => stakeholder.account === invoiceDatas.value.stakeholder_account )
            console.log("newStakeholder", newStakeholder)
            invoiceDatas.value.stakeholder_id = newStakeholder[0].stakeholder_id
        }


        onMounted(async () => {
            window.addEventListener('scroll', handleScroll);

        });

        // Watch pour surveiller les changements de companySelected
        watch(companySelected, async (newVal) => {
            if (newVal && newVal.evoliz_id) {
                console.log("Company selected has been updated:", newVal);
                await fetchAccounts();
            }
        }, { immediate: true });

        onBeforeUnmount(() => {
            window.removeEventListener('scroll', handleScroll);
        });

        return {
            handleSelect,
            loading,
            pdfUrl,
            isTemp,
            isImmo,
            changeIsTemp,
            accounts,
            companySelected,
            //Données d'une Facture
            invoiceDatas,
            stakeholderInfos,
            registrationValue,
            invoicePaymentMethod,
            supplierType,
            marketPlaceName,
            country,
            //Gestion des produits
            products,
            addProduct,
            removeProduct,
            duplicateAccount,
            //Gestion des écritures comptables
            accountingEntriesComputed,
            //Gestion des comptes
            filterAccounts,
            filteredAccounts,
            //Gestion de la TVA
            tvaAccounts,
            //Gestion des vérifications
            verifyEntireInvoice,
            verifyAccountingEntries,
            //Gestion des immo
            isOneImmo,
            handleOneImmo,
            //Gestion des frais annexes
            extraFees,
            addExtraFees,
            removeExtraFees,
            //Validation des données
            approveData,
            sendToEvoliz,
            updateData,
            isLoading,
            validatedInvoices,
            confirmationMessage,
            showMessage,
            messageColor,
            deleteInvoiceTemp,
            confirmDelete,
            downloadFec,
            //Gestion de l'animation
            isScrolledPast,
            //Gestion de l'ajout d'un nouveau compte d'achat
            isAddingNewAccount,
            newPurchaseAccount,
            toggleNewAccountForm,
            saveNewPurchaseAccount,
            units,
            getTvaAccountPlaceholder,
            showTvaWarning,
            isSaving,
            //gestion des comptes fournisseurs
            companyStakeholder,
            //Gestion de la conversion HT -> TTC
            convertHtToTtc,
            convertTtcToHt,
            //Type de paiements
            paymentMethods,
            //Liste des marketplaces
            marketplaces,
            downloadFec2,
            productMessage,
            productMessageColor,
            updateStakeholderAccount



        };
    }
};
</script>

<style scoped>
.container {
    display: flex;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.form {
    width: 50%;
    display: flex;
    flex-direction: column;
}

.preview {
    width: 50%;
    margin-left: 1%
}


.sticky-wrapper {
    width: 100%;
}

.invoicesDatasForm {
    padding: 20px;
}

.form-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.supplier-account-select {
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.form-row {
    display: flex;
    align-items: center;
}

.form-row label {
    flex: 0 0 200px;
    /* Largeur fixe pour les labels */
    margin-right: 10px;
}

.form-input {
    flex: 1;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

h5 {
    margin-bottom: 20px;
}

.productsForm {
    padding: 1em;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-top: 1em;
}

.productsForm h5 {
    margin-bottom: 1em;
}

.productsList {
    padding: 1em;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 1em;
}

.form-group {
    display: flex;
    align-items: center;
    margin-bottom: 1em;
}

label {
    width: 150px;
    font-weight: bold;
    margin-right: 1em;
}

input,
select {
    flex: 1;
    padding: 0.5em;
    border: 1px solid #ddd;
    border-radius: 5px;
}

button {
    margin-top: 0.5em;
}

.verificationResult .icon {
    display: inline-block;
    margin-right: 0.5em;
}

input[type="text"],
input[type="number"],
.custom-select {
    flex: 1;
    padding: 0.5em;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.tvaForm {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.tvaForm>div {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;
    justify-content: center;
}

.tvaForm>div>span {
    width: 150px;
    margin-right: 10px;
    text-align: right;
}

.tvaForm>div>input {
    flex-grow: 0.5;
}

.table-container {
    margin: 20px 0;
    overflow-x: auto;
    /* Ajout du défilement horizontal */
}

.table-container h5 {
    margin-bottom: 15px;
    font-size: 1.5em;
    color: #333;
}

table {
    width: 100%;
    border-collapse: collapse;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
}

thead {
    background-color: #f4f4f4;
}

th,
td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

th {
    background-color: #f8f8f8;
    font-weight: bold;
    color: #555;
}

tr:hover {
    background-color: #f1f1f1;
}

tbody tr:nth-child(even) {
    background-color: #f9f9f9;
}

tbody tr:nth-child(odd) {
    background-color: #ffffff;
}

tbody td {
    color: #555;
}

.verificationResult {
    display: flex;
    align-items: center;
    margin-top: 10px;
    transition: all 0.5s ease-in-out;

}

.icon.success {
    color: green;
}

.icon.error {
    color: red;
}

.icon {
    font-size: 16px;
    margin-right: 10px;
}

.sticky-wrapper {
    position: sticky;
    top: 0;
}

.action-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.button-group {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}

.btn {
    width: 200px;
}

.loading-spinner {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
}

.confirmation-message {
    margin-top: 10px;
    font-size: 1.2em;
    text-align: center;
}

.validated-invoices {
    font-size: 1.1em;
    margin-top: 10px;
    font-weight: bold;
}

.fixed-rectangle {
    position: fixed;
    top: 5px;
    right: 10px;
    width: 300px;
    padding: 10px;
    background-color: white;
    border: 1px solid #ccc;
    font-size: 16px !important;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}
.success-message {
    color: green;
}
.error-message {
    color: red;
}
</style>