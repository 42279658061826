<template>
  <div class="addCompanyContainer q-pa-md">
    <h3 class="text-center text-h5 q-mb-md"><b>AJOUTER UNE NOUVELLE ENTREPRISE</b></h3>
    
    <div class="form q-gutter-md">
      <q-input color="orange-1-12" v-model="siret" label="Renseignez le SIREN" class="q-mb-md">
        <template v-slot:prepend>
          <q-icon name="business" />
        </template>
      </q-input>
      
      <q-input color="orange-1-12" v-model="evolizId" label="Entrez le company_id d'Evoliz" class="q-mb-md">
        <template v-slot:prepend>
          <q-icon name="badge" />
        </template>
      </q-input>
      
      <q-input color="orange-1-12" v-model="evolizUserId" label="Entrez le user_id d'Evoliz" class="q-mb-md">
        <template v-slot:prepend>
          <q-icon name="person" />
        </template>
      </q-input>
      
      <p v-if="!societyFound && name" class="text-negative q-mb-md">La société n'a pas été trouvée</p>
      
      <q-btn color="primary" label="Envoyer" @click="getCompanyInfos" class="q-mb-md" />
    </div>
    
    <q-separator class="q-my-md" />

    <div v-if="name" class="submitCompanyInfo q-gutter-md">
      <q-input dense v-model="name" label="Dénomination sociale" class="q-mb-md" />
      <q-input dense v-model="adress" label="Adresse" class="q-mb-md" />
      <q-input dense v-model="adress2" label="Complément d'adresse" class="q-mb-md" />
      <q-input dense v-model="postal" label="Code postal" class="q-mb-md" />
      <q-input dense v-model="city" label="Ville" class="q-mb-md" />
      <q-input dense v-model="mail" label="Email" class="q-mb-md" />
      <q-input dense v-model="phone" label="Téléphone" class="q-mb-md" />
      <q-input dense v-model="legalForm" label="Forme juridique" class="q-mb-md" />
      <q-input dense v-model="creationDate" label="Date de création" class="q-mb-md" />
      <q-input dense v-model="siret" label="Siret" class="q-mb-md" />
      <q-input dense v-model="siren" label="Siren" class="q-mb-md" />
      <q-input dense v-model="vatNumber" label="Numéro de TVA intracommunautaire" class="q-mb-md" />
      <q-input dense v-model="rcsName" label="Numéro RCS" class="q-mb-md" />
      <q-input dense v-model="rcsDate" label="Date d'immatriculation RCS" class="q-mb-md" />
      <q-input dense v-model="capital" label="Capital social" class="q-mb-md" />
      <q-input dense v-model="corporateObject" label="Catégorie" class="q-mb-md" />
      <q-input dense v-model="apeCode" label="Code NAF/APE" class="q-mb-md" />
      <q-input dense v-model="tradeName" label="Nom commercial" class="q-mb-md" />
      <q-input dense v-model="declaredMainActivity" label="Activité principale déclarée" class="q-mb-md" />
      <q-input dense v-model="addressEstablishment" label="Adresse de l'établissement" class="q-mb-md" />
      <q-input dense v-model="website" label="Site internet" class="q-mb-md" />
      <q-input dense v-model="collectiveAgreementNumber" label="Numéro de convention collective" class="q-mb-md" />
      <q-input dense v-model="accountingEndDate" label="Date de fin d'exercice" class="q-mb-md" />
      <q-select dense v-model="tvaRegime" :options="['franchise', 'RSI', 'RN']" label="Choisissez le régime de TVA" class="q-mb-md" />
      <q-input dense v-model="companyCode" label="Code de l'entreprise" class="q-mb-md" />
      
      <div class="board">
        <h5><b>Dirigeant(s)</b></h5>
        <div v-for="(dirigeant, index) in dirigeants" :key="dirigeant.id" class="q-gutter-md q-mb-md">
          <p><b>Dirigeant {{ index + 1 }}</b></p>
          <q-input dense v-model="dirigeants[index].name" label="Nom" class="q-mb-md" />
          <q-input dense v-model="dirigeants[index].name2" label="Prénom" class="q-mb-md" />
          <q-input dense v-model="dirigeants[index].roleStartDate" label="Date de nomination" class="q-mb-md" />
          <q-input dense v-model="dirigeants[index].dirigeantAddress" label="Adresse" class="q-mb-md" />
          <q-input dense v-model="dirigeants[index].dirigeantPhone" label="Téléphone" class="q-mb-md" />
          <q-input dense v-model="dirigeants[index].dirigeantAccount" label="Compte" class="q-mb-md" />
          <q-separator spaced size="3px" color="black"/>
        </div>
        <q-btn dense label="Ajouter un dirigeant" @click="addDirigeant = true" class="q-mb-md" />
      </div>
      <br>
      <div class="board">
        <h5><b>Associé(s)</b></h5>
        <div v-for="(associate, index) in associates" :key="associate.name" class="q-gutter-md q-mb-md">
          <p><b>Associé {{ index + 1 }}</b></p>
          <q-input dense v-model="associates[index].legalForm" label="Forme juridique/titre" class="q-mb-md" />
          <q-input dense v-model="associates[index].name" label="Dénomination/Nom+Prenom" class="q-mb-md" />
          <q-input dense v-model="associates[index].siretOrNif" label="Siret ou NIF" class="q-mb-md" />
          <q-input dense v-model="associates[index].address" label="Adresse" class="q-mb-md" />
          <q-input dense v-model="associates[index].phone" label="Téléphone" class="q-mb-md" />
          <q-input dense v-model="associates[index].mail" label="Email" class="q-mb-md" />
          <q-input dense v-model="associates[index].country" label="Pays" class="q-mb-md" />
          <q-select dense v-model="associates[index].regime" :options="['IS', 'IR']" label="Choisissez le régime de Taxation" class="q-mb-md" />
          <q-input dense v-model="associates[index].commentary" label="Commentaire" class="q-mb-md" />
          <q-input dense v-model="associates[index].account" label="Compte" class="q-mb-md" />
          <q-input dense v-model="associates[index].ownershipPercentage" label="Pourcentage de détention" class="q-mb-md" />
          <q-input dense v-model="associates[index].roleStartDate" label="Date d'acquisition de la qualité d'associé" class="q-mb-md" />

          <q-separator spaced size="3px" color="black"/>
        </div>
        <q-btn dense label="Ajouter un associé" @click="addAssociate = true" class="q-mb-md" />
      </div>
      <br>
      <div class="board">
        <h5><b>Participation(s)</b></h5>
        <div v-for="(participation, index) in participations" :key="participation.companyName" class="q-gutter-md q-mb-md">
          <p><b>Participation {{ index + 1 }}</b></p>
          <q-input dense v-model="participations[index].companyName" label="Nom de l'entreprise" class="q-mb-md" />
          <q-input dense v-model="participations[index].siret" label="Siret" class="q-mb-md" />
          <q-input dense v-model="participations[index].address" label="Adresse" class="q-mb-md" />
          <q-input dense v-model="participations[index].phone" label="Téléphone" class="q-mb-md" />
          <q-input dense v-model="participations[index].mail" label="Email" class="q-mb-md" />
          <q-input dense v-model="participations[index].country" label="Pays" class="q-mb-md" />
          <q-select dense v-model="participations[index].regime" :options="['IS', 'IR']" label="Choisissez le régime de Taxation" class="q-mb-md" />
          <q-input dense v-model="participations[index].commentary" label="Commentaire" class="q-mb-md" />
          <q-input dense v-model="participations[index].account" label="Compte" class="q-mb-md" />
          <q-input dense v-model="participations[index].ownershipPercentage" label="Pourcentage de détention" class="q-mb-md" />
          <q-separator spaced size="3px" color="black"/>
        </div>
        <q-btn dense label="Ajouter une participation" @click="addParticipation = true" class="q-mb-md" />
      </div>
      <q-input dense v-model="comment" label="Commentaire" class="q-mb-md" />
      <q-btn color="secondary" label="Valider" @click="submitCompanyInfo" class="q-mb-md" />
    </div>
    
    <div v-if="companyId" class="infos q-my-md">
      <p>Id de l'entreprise : </p>
      <span>{{ companyId }}</span>
    </div>
    
    <div class="banners">
      <q-banner v-if="saved" class="bg-secondary text-white q-mb-md">
        Données enregistrées
      </q-banner>
      <q-banner v-if="errorSaved" class="text-white bg-red q-mb-md">
        Erreur pendant l'enregistrement
      </q-banner>
    </div>
    
    <div class="popup">
      <q-dialog v-model="addDirigeant">
        <q-card style="width: 400px;">
          <q-card-section>
            <div class="text-h6">Ajouter un dirigeant</div>
          </q-card-section>

          <q-card-section class="q-pt-none q-gutter-md">
            <q-input dense v-model="dirigeantName" label="Nom" />
            <q-input dense v-model="dirigeantName2" label="Prenom" />
            <q-input dense v-model="roleStartDate" label="Date de nomination" />
            <q-input dense v-model="dirigeantAddress" label="Adresse" />
            <q-input dense v-model="dirigeantPhone" label="Téléphone" />
            <q-input dense v-model="dirigeantMail" label="Email" />
          </q-card-section>

          <q-card-actions align="right">
            <q-btn flat label="Ajouter" color="primary" v-close-popup @click="handleAddDirigeant" />
          </q-card-actions>
        </q-card>
      </q-dialog>
      
      <q-dialog v-model="addAssociate">
        <q-card style="width: 400px;">
          <q-card-section>
            <div class="text-h6">Ajouter un associé</div>
          </q-card-section>

          <q-card-section class="q-pt-none q-gutter-md">
            <q-input dense v-model="associate.legalForm" label="Forme juridique/titre" />
            <q-input dense v-model="associate.name" label="Dénomination/Nom+Prenom" />
            <q-input dense v-model="associate.siretOrNif" label="Siret ou NIF" />
            <q-input dense v-model="associate.address" label="Adresse" />
            <q-input dense v-model="associate.phone" label="Téléphone" />
            <q-input dense v-model="associate.mail" label="Email" />
            <q-input dense v-model="associate.country" label="Pays" />
            <q-select dense v-model="associate.regime" :options="['IS', 'IR']" label="Choisissez le régime de Taxation" />
            <q-input dense v-model="associate.commentary" label="Commentaire" />
            <q-input dense v-model="associate.account" label="Compte" />
            <q-input dense v-model="associate.ownershipPercentage" label="Pourcentage de détention" />
            <q-input dense v-model="associate.roleStartDate" label="Date d'entrée de la qualité d'associé" />
          </q-card-section>

          <q-card-actions align="right">
            <q-btn flat label="Ajouter" color="primary" v-close-popup @click="handleAddAssociate" />
          </q-card-actions>
        </q-card>
      </q-dialog>
      
      <q-dialog v-model="addParticipation">
        <q-card style="width: 400px;">
          <q-card-section>
            <div class="text-h6">Ajouter une participation</div>
          </q-card-section>

          <q-card-section class="q-pt-none q-gutter-md">
            <q-input dense v-model="participation.companyName" label="Nom de l'entreprise" />
            <q-input dense v-model="participation.siret" label="Siret" />
            <q-input dense v-model="participation.address" label="Adresse" />
            <q-input dense v-model="participation.phone" label="Téléphone" />
            <q-input dense v-model="participation.mail" label="Email" />
            <q-input dense v-model="participation.country" label="Pays" />
            <q-select dense v-model="participation.regime" :options="['IS', 'IR']" label="Choisissez le régime de Taxation" />
            <q-input dense v-model="participation.commentary" label="Commentaire" />
            <q-input dense v-model="participation.account" label="Compte" />
            <q-input dense v-model="participation.ownershipPercentage" label="Pourcentage de détention" />
          </q-card-section>

          <q-card-actions align="right">
            <q-btn flat label="Ajouter" color="primary" v-close-popup @click="handleAddParticipation" />
          </q-card-actions>
        </q-card>
      </q-dialog>
    </div>
  </div>
</template>


<script>
import { ref } from "vue";
import axios from "axios";
export default {
  setup() {
    let siren = ref(null);
    let name = ref(null);
    let adress = ref(null);
    let adress2 = ref(null);
    let postal = ref(null);
    let city = ref(null);
    let phone = ref(null);
    let mail = ref(null);
    let saved = ref(false);
    let legalForm = ref(null);
    let creationDate = ref(null);
    let siret = ref(null);
    let vatNumber = ref(null);
    let rcsName = ref(null);
    let rcsDate = ref(null);
    let capital = ref(null);
    let corporateObject = ref(null);
    let apeCode = ref(null);
    let dirigeant = ref(null);
    let associate = ref({});
    let associates = ref([]);
    let comment = ref(null);
    let errorSaved = ref(false);
    let addDirigeant = ref(false);
    let companyId = ref(null);
    let siretRules = [val => (val.length == 14) || 'Siret non valide']
    let dirigeantName = ref(null);
    let dirigeantName2 = ref(null);
    let roleStartDate = ref(null);
    let dirigeantAddress = ref(null);
    let dirigeantPhone = ref(null)
    let dirigeants = ref([]);
    let addAssociate = ref(false);
    let addParticipation = ref(false);
    let societyFound = ref(false);
    let tradeName = ref(null);
    let declaredMainActivity = ref(null);
    let addressEstablishment = ref(null);
    let website = ref(null);
    let evolizId = ref(null);
    let collectiveAgreementNumber = ref(null);
    let accountingEndDate = ref(null);
    let tvaRegime = ref(null);
    let companyCode = ref(null);
    let participations = ref([]);
    let participation = ref({});
    let evolizUserId = ref(null);
    let dirigeantMail = ref(null);
    const getCompanyInfos = async () => {
      try {
        console.log("Get company infos...")
        let url = `${process.env.VUE_APP_BASE_URL}/companyInfos/` + siret.value;
        let response = await axios.get(url);
        let infos = response.data.data;
        societyFound.value = true;
        // Mise à jour des valeurs en fonction de la nouvelle structure JSON
        name.value = infos.denomination;
        adress.value = infos.siege.adresse_ligne_1 + " " + infos.siege.adresse_ligne_2;
        adress2.value = infos.siege.adresse_ligne_3;
        postal.value = infos.siege.code_postal;
        city.value = infos.siege.ville;
        creationDate.value = infos.date_creation_formate;
        capital.value = infos.capital;
        siren.value = infos.siren_formate;
        vatNumber.value = infos.numero_tva_intracommunautaire;
        rcsName.value = infos.numero_rcs;
        corporateObject.value = infos.domaine_activite;
        apeCode.value = infos.code_naf;
        legalForm.value = infos.forme_juridique;
        declaredMainActivity.value = infos.objet_social;
        accountingEndDate.value = infos.prochaine_date_cloture_exercice;
        // Traitement du dirigeant principal
        if (infos.representants && infos.representants.length > 0) {
          const dirigeant = infos.representants[0];
          dirigeants.value = [{
            id: generateUniqueId(),
            name: dirigeant.nom,
            name2: dirigeant.prenom,
            roleStartDate: dirigeant.date_prise_de_poste,
            dirigeantAddress: dirigeant.adresse_ligne_1 + " " + dirigeant.adresse_ligne_2,
            dirigeantPhone: "", // A déterminer selon la structure des données
          }];
        }

      } catch (error) {
        societyFound.value = false;
        console.log(error);
      }
    }


    const handleAddDirigeant = () => {
      let obj = {
        "id": generateUniqueId(),
        "name": dirigeantName.value,
        "name2": dirigeantName2,
        "roleStartDate": roleStartDate,
        "dirigeantAddress": dirigeantAddress,
        "dirigeantPhone": dirigeantPhone,
        "mail": dirigeantMail
      }
      dirigeants.value.push(obj);
    }

    const handleAddAssociate = () => {
      associates.value.push(associate.value);
      addAssociate.value = false;
      associate.value = {};
    }

    const handleAddParticipation = () => {
      participations.value.push(participation.value);
      addParticipation.value = false;
      participation.value = {};
    }

    const submitCompanyInfo = async () => {
      try {
        let postBody = {
          name: name.value,
          siret: siret.value,
          adress: adress.value,
          adress2: adress2.value,
          postal: postal.value,
          city: city.value,
          mail: mail.value,
          phone: phone.value,
          creationDate: creationDate.value,
          capital: capital.value,
          siren: siren.value,
          vatNumber: vatNumber.value,
          rcsName: rcsName.value,
          corporateObject: corporateObject.value,
          apeCode: apeCode.value,
          legalForm: legalForm.value,
          dirigeants: dirigeants.value,
          tradeName: tradeName.value,
          declaredMainActivity: declaredMainActivity.value,
          addressEstablishment: addressEstablishment.value,
          website: website.value,
          collectiveAgreementNumber: collectiveAgreementNumber.value,
          accountingEndDate: accountingEndDate.value,
          tvaRegime: tvaRegime.value,
          companyCode: companyCode.value,
          associates: associates.value,
          participations: participations.value,
          comment: comment.value,
          evolizId: evolizId.value,
          evolizUserId: evolizUserId.value
        }
        let url = `${process.env.VUE_APP_BASE_URL}/companyInfos/`;
        let response = await axios.post(url, postBody);
        companyId.value = response.data.id
        saved.value = true;
        setTimeout(() => {
          saved.value = false;
        }, 3000)
      } catch (error) {
        console.log(error)
        errorSaved.value = true;
        setTimeout(() => {
          errorSaved.value = false;
        }, 3000)
      }
    }

    const generateUniqueId = () => {
      return '_' + Math.random().toString(36).substr(2, 9);
    };
    return {
      name,
      adress,
      adress2,
      postal,
      city,
      siren,
      phone,
      saved,
      errorSaved,
      companyId,
      getCompanyInfos,
      submitCompanyInfo,
      siretRules,
      legalForm,
      creationDate,
      siret,
      vatNumber,
      rcsName,
      rcsDate,
      capital,
      corporateObject,
      apeCode,
      dirigeant,
      associate,
      comment,
      addDirigeant,
      handleAddDirigeant,
      roleStartDate,
      dirigeantName,
      dirigeantName2,
      dirigeantAddress,
      dirigeantPhone,
      dirigeants,
      addAssociate,
      societyFound,
      tradeName,
      declaredMainActivity,
      addressEstablishment,
      website,
      collectiveAgreementNumber,
      evolizId,
      accountingEndDate,
      tvaRegime,
      companyCode,
      associates,
      handleAddAssociate,
      addParticipation,
      handleAddParticipation,
      participations,
      participation,
      evolizUserId,
      mail,
      dirigeantMail
    }
  }
}
</script>

<style scoped>
.addCompanyContainer {
  width: 50%;
  margin: auto;
}

.form {
  width: 40%;
  margin: auto;
}

.submitCompanyInfo {
  width: 60%;
  margin: auto;
}

.banners {
  width: 30%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

div .q-field__messages {
  color: #F44336 !important;
}

.q-input .text-negative {
  color: #F44336 !important;

}

.q-field--error .q-field__bottom {
  color: #F44336 !important;
}

.board{
  border: 1px solid;
    padding: 1%;
    border-radius: 5px;
}
</style>