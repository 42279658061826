<template>
  <div class="fixed inset-0 flex items-center justify-center bg-gray-200 bg-opacity-80">
    <div class="bg-white  rounded-lg shadow-lg max-w-2xl w-full max-h-screen overflow-y-auto">
      <div class="flex items-center justify-between p-4 md:p-2 border-b rounded-t">
        <h3 class="text-xl font-semibold text-[#F72585] font-title">Télécharger un fichier</h3>
      </div>
      <div class="p-4 text-[#7209B7]">
        <label class="block mb-4 text-base">Choisissez le dossier parent</label>

        <!-- Utilisation du composant TreeView -->
        <TreeView :folderList="folderList" :selectedFolderId="selectedParentFolderId" @folder-selected="selectFolder" />

        <!-- Affiche le dossier sélectionné -->
        <p v-if="selectedParentFolderName" class="mt-4 text-sm font-medium">
          Dossier sélectionné : {{ selectedParentFolderName }}
        </p>

        <input type="file" ref="fileInput" class="mb-4 w-full border p-2 text-sm" @change="handleFileChange" />
        <div class="flex justify-end space-x-4">
          <button @click="$emit('close')"
            class="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            Annuler
          </button>
          <button @click="uploadFile"
            class="text-white inline-flex items-center bg-[#7209B7] hover:bg-[#4CC9F0] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
            Télécharger
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import TreeView from './TreeView.vue';

// Props
const props = defineProps({
  show: Boolean,
  folderSelected: String,
});

// Emissions
const emit = defineEmits(['close']);

// Références
const fileInput = ref(null);
const folderList = ref([]);
const selectedParentFolderId = ref(null);
const selectedParentFolderName = ref('');
const selectedFile = ref(null);

// Charger les dossiers initiaux depuis l'API
const getFolderList = async () => {
  try {
    const url = props.folderSelected;
    const regex = /embed\/([^?]+)/;
    const match = url.match(regex);
    const parentFolderId = match ? match[1] : null;

    const response = await axios.get(`${process.env.VUE_APP_BASE_URL}/zohoSubfolders/${parentFolderId}`);
    folderList.value = response.data.map(folder => ({
      id: folder.id,
      name: folder.attributes.name,
      children: [],
      open: false,
      childrenLoaded: false,
      hasChildren: true,
    }));
  } catch (error) {
    console.error('Erreur lors du chargement des dossiers', error);
  }
};

// Méthode pour gérer la sélection du fichier
const handleFileChange = (event) => {
  const file = event.target.files[0];
  if (file) {
    selectedFile.value = file;
  }
};

// Sélection d'un dossier parent
const selectFolder = (folder) => {
  selectedParentFolderId.value = folder.id;
  selectedParentFolderName.value = folder.name;
};

// Convertir le fichier en base64
const toBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(',')[1]);
    reader.onerror = error => reject(error);
  });
};

// Méthode pour uploader un fichier
const uploadFile = async () => {
  if (selectedFile.value && selectedParentFolderId.value) {
    try {
      const base64File = await toBase64(selectedFile.value);

      const payload = {
        base64File,
        filename: selectedFile.value.name,
        parentId: selectedParentFolderId.value,
        mimeType: selectedFile.value.type,
      };

      const response = await axios.post(`${process.env.VUE_APP_BASE_URL}/uploadToZoho`, payload);

      if (response.status === 200) {
        alert('Fichier uploadé avec succès !');
        emit('close');
      } else {
        throw new Error('Erreur lors de l\'upload.');
      }
    } catch (error) {
      console.error('Erreur lors de l\'upload du fichier:', error);
      alert('Erreur lors du téléchargement du fichier.');
    }
  } else {
    alert('Veuillez sélectionner un fichier et un dossier parent.');
  }
};

onMounted(() => {
  getFolderList();
});
</script>

<style scoped>
.font-title {
  font-family: 'Righteous', cursive;
}
</style>
