<template>
  <div>
    <div class="flex justify-between items-center p-2 bg-[#7209B7] shadow-lg w-full">

      <div class="flex items-center">
        <MenuTabs />
      </div>

      <div class="text-center">
        <img src="@/assets/logo-Taac.jpeg" alt="TaaC Logo" class="w-16 h-16 rounded-full object-cover" />
        <span class="text-2xl font-bold text-[#F7287B]">TaaC</span>
      </div>

      <div>
        <select v-model="applicationSelected" class="w-72 bg-pink-600 text-white p-2 rounded-md"
          @change="handleApplicationChange">
          <option value="" disabled selected>Accédez aux Autres Applications</option>
          <option v-for="application in applications" :value="application">{{ application.name }}</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import axios from "axios";
import { onMounted } from "@vue/runtime-core";
import { useStore } from "vuex";
import { getCurrentInstance } from "vue";
import MenuTabs from "@/components/MenuTabs.vue";
import router from "@/router";

export default {
  components: { MenuTabs },
  setup() {
    const internalInstance = getCurrentInstance();
    const emitter = internalInstance.appContext.config.globalProperties.emitter;
    const store = useStore();
    let options = ref(null);
    let selected = ref("Selectionner un client");
    const applicationSelected = ref("");
    let clients = ref(null);
    let clientName = computed(() => { if (store.state.currentClientInfos) { return store.state.currentClientInfos.name } else return "" });
    let role = ref();
    let right = ref();
    let fixed = ref(false);

    const applications = ref([
      { id: 0, name: "Toutes les applications", path: "/interPage" },
      { id: 1, name: "ArchiPilot", path: "/archiPilot" },
      { id: 2, name: "FacturEase", path: "/iaPreview2" },
      { id: 3, name: "ArchiGED", path: "/archiGED" },
    ]);

    const handleApplicationChange = (event) => {
      let applicationSelectedId = applicationSelected.value.id
      let application = applications.value.filter(
        (application) => application.id == applicationSelectedId
      );
      router.push({ path: application[0].path });
    };

    const moveToAdd = () => {
      router.push({ path: "/addCompany/" });
      emitter.emit("moveToOther");
    };

    const handleClientChange = () => {
      if (selected.value == "Ajouter une nouvelle entreprise") {
        moveToAdd()
        return
      }
      let selectedClient = clients.value.filter(
        (client) => client.name == selected.value
      );
      store.commit("setCurrentClient", selectedClient[0].id);
      emitter.emit("clientChanged");
    };
    const getClient = async () => {
      let user = store.state.user
      var response = await axios.get(`${process.env.VUE_APP_BASE_URL}/companies`);
      var result = [];

      if (user.role == "super_admin") {
        clients.value = response.data;
        response.data.forEach((element) => {
          result.push(element.name);
        });
      } else {
        var userCompanies = store.state.companies
        let allCompanies = response.data;
        result = allCompanies.filter(company =>
        userCompanies.some(userCompany => String(userCompany.taac_company_id) === String(company.id))
        );
      }
      options.value = result;
    }

    const logout = () => {

    }
    onMounted(getClient);
    return {
      options,
      getClient,
      handleClientChange,
      selected,
      clients,
      fixed,
      moveToAdd,
      role,
      right,
      clientName,
      logout,
      handleApplicationChange,
      applications,
      applicationSelected
    };
  },
};
</script>

<style scoped>
.header {
  display: flex;
  flex-direction: row;
  padding: 1%;
  background-color: #7869D3;
  font-size: 2.5em;
  color: white
}

.header-left {
  display: flex;
  flex-direction: row;
  flex-basis: 10%;
  align-items: center;
}

.header-center {
  display: flex;
  flex-direction: row;
  flex-basis: 80%;
  align-items: center;
  margin: auto;
  justify-content: center;

}

.header-right {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-basis: 10%;
  align-items: center;
}
</style>